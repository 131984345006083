import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import config from '../../../Config';
import Pagination from '../General/Pagination';
import moment from 'moment';
import { NumberFormat, NumberFormat3D, NumberFormatEmpty } from "../General/Functions";
import ReactDOMServer from 'react-dom/server';
import html2canvas from "html2canvas";
import generatePDF from "../../Common/PdfUtils";
import EstatementPrint from "./EstatementPrint";
import { FaceFrownIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import BsPagination from "../BsPagination";
import LoginCheck from "../General/LoginCheck";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function EStatement(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const Currency = localStorage.getItem("Currency");
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [AccountType, setAccountType] = useState("");
    const [startDate, setStartDate] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [activeWallet, setactiveWallet] = useState("");
    const [passiveWallet, setpassiveWallet] = useState("");
    const [rewardWallet, setrewardWallet] = useState("");
    const [totalDebit, settotalDebit] = useState("");
    const [totalCredit, settotalCredit] = useState("");
    const [totalEarned, settotalEarned] = useState("");
    const [cashBalance, setcashBalance] = useState("");
    const [openingBalance, setOpeningBalance] = useState("");
    const [closingBalance, setClosingBalance] = useState("");
    const [MemberInfo, setMemberInfo] = useState({});



    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [ledgerOld, setLedgerOld] = useState([]);
    const [isFilteredByDate, setisFilteredByDate] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            startDate: startDate,
            endDate: endDate
        };
        //  console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Account/GetAccountStatement',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    //console.log(response.data);
                    setLedger(response.data.ListofLedger);
                    setLedgerOld(response.data.ListofLedger);
                    setTotalRecords(response.data.totalRecords);
                    setAccountType(response.data.accountType);
                    setactiveWallet(response.data.activeWallet);
                    setpassiveWallet(response.data.passiveWallet);
                    setrewardWallet(response.data.rewardWallet);
                    settotalDebit(response.data.totalDebit);
                    settotalCredit(response.data.totalCredit);
                    settotalEarned(response.data.totalEarned);
                    setcashBalance(response.data.cashBalance);
                    setOpeningBalance(response.data.openingBalance);
                    setClosingBalance(response.data.closingBalance);
                    setLoading(false);
                }
                else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/login");
                    localStorage.clear();
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                }


            })
            .catch(function (error) {
                console.log(error);
            });





    }, [pageNo, pageSize, startDate, endDate]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {
        if (listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {

                    return (
                        <tr key={index}>

                            <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
                            <td>{item.TransactionDetails}</td>
                            <td>{item.ReferenceNo}</td>
                            <td>{NumberFormatEmpty(item.Debit)}</td>
                            <td>{NumberFormatEmpty(item.Credit)}</td>
                            <td>{NumberFormat(item.CashBalance)}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="6">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }
    }




    const handleDateFilters = (e) => {

        e.preventDefault();
        e.stopPropagation();


        let from = e.target.from.value;
        let to = e.target.to.value;

        setStartDate(from);
        setEndDate(to);


    }

    useEffect(() => {


        const Parms = {
            ID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetMemberInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setMemberInfo(response.data);
                    setLoading(false);
                }
                else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/login");
                    localStorage.clear();
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                }

            })
            .catch(function (error) {
                console.log(error);
            });



    }, []);


    const printPdfData = () => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: 1,
            pageSize: 10000000,
            startDate: startDate,
            endDate: endDate
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Account/GetAccountStatement',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    //console.log(response.data);
                    let data = response.data.ListofLedger;



                    let AccountType = response.data.accountType;
                    let activeWallet = response.data.activeWallet;
                    let passiveWallet = response.data.passiveWallet;
                    let rewardWallet = response.data.rewardWallet;
                    let totalDebit = response.data.totalDebit;
                    let totalCredit = response.data.totalCredit;
                    let cashBalance = response.data.cashBalance;
                    let OpeningBalance = response.data.openingBalance;
                    let ClosingBalance = response.data.closingBalance;


                    const htmlString = ReactDOMServer.renderToString(<EstatementPrint data={data}
                        AccountType={AccountType}
                        activeWallet={activeWallet}
                        passiveWallet={passiveWallet}
                        rewardWallet={rewardWallet}
                        totalDebit={totalDebit}
                        totalCredit={totalCredit}
                        cashBalance={cashBalance}
                        OpeningBalance={OpeningBalance}
                        ClosingBalance={ClosingBalance}
                        user={MemberInfo}
                        startDate={startDate}
                        endDate={endDate}


                    />);
                    generatePDF(htmlString, "E-Statement");

                }


            })
            .catch(function (error) {
                console.log(error);
            });


    }


    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize); // Assuming you have a state variable for pageSize
    };


    return (
        <>
            <LoginCheck />
            <div className="section-title">Account statement</div>
            <ul className="statement-info">
                <li>
                    <p>Total debit</p>
                    <span>{Currency} {NumberFormat(totalDebit)}</span>

                </li>
                <li>
                    <p>Total credit</p>
                    <span>{Currency} {NumberFormat(totalCredit)}</span>

                </li>
                <li>
                    <p>Cash balance</p>
                    <span>{Currency} {NumberFormat(cashBalance)}</span>

                </li>
            </ul>
            <p>Default range of transaction details is 1 day prior to the current date. Select the date range to change the following.</p>
            <div className="page-filters form_default mb-3">
                <form method="post" onSubmit={handleDateFilters}>
                    <div className="">
                        <div className="row gy-3 align-items-center">
                            <div className="col-md-3">
                                <div className="white-input">
                                    <input
                                        type="date"
                                        name="from"
                                        className="form-control"
                                        placeholder="From"
                                        onChange={(e) => setStartDate(e.target.value)}
                                        defaultValue={moment().subtract(1, 'days').format(config.date_format_input)} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="white-input">
                                    <input
                                        type="date"
                                        name="to"
                                        className="form-control"
                                        placeholder="To"
                                        onChange={(e) => setEndDate(e.target.value)}
                                        max={moment().format(config.date_format_input)}
                                        defaultValue={moment().format(config.date_format_input)} />
                                </div>
                            </div>
                            <div className="col-md-2 col-6">
                                <button type="submit" className="button white-button button-icon-bs"><span>Generate</span><TableCellsIcon /></button>
                            </div>
                            <div className="col-md-1 col-6 text-end d-none">
                                <button type="button" className="button button-primary mt-md-4">Email</button>
                            </div>
                            <div className="col-md-3 text-end col-12 d-none">
                                <div className="filters-right mt-md-4 w-100">
                                    <div className="dropdown dropdown-gradient2 default-light w-100">
                                        <button className="btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span>Export</span>
                                            <i className="material-symbols-outlined">expand_more</i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" onClick={printPdfData} href="#">PDF</a></li>
                                            <li><a className="dropdown-item" href="#">Word</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div >
            <div className="statement-table mt-4 mb-5">
                <div className="partner-table">
                    <div className="table-wrap">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th colSpan='3'>Statement for period <b>{startDate}</b> to <b>{endDate}</b></th>
                                    <th colSpan='3' className="text-end">
                                        {
                                            listOfLedger != null && listOfLedger.length > 0 &&
                                            <>
                                                {
                                                    pageNo == 1 ?
                                                        'Opening Balance'
                                                        :
                                                        'Balance Brought Forward'
                                                }
                                                <b> {Currency} {NumberFormat(openingBalance)}</b>
                                            </>
                                        }
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        Transaction
                                    </th>
                                    <th>
                                        Reference No
                                    </th>
                                    <th>
                                        Debit
                                    </th>
                                    <th>
                                        Credit
                                    </th>
                                    <th>
                                        Cash Balance
                                    </th>
                                </tr>
                            </thead>
                            <tbody>




                                {
                                    loading ?
                                        <tr>
                                            <td className="text-center" colSpan="8">
                                                <Spinner animation="border" role="status"></Spinner>
                                            </td>
                                        </tr>
                                        :
                                        <>
                                            {renderLedger()}
                                            {
                                                listOfLedger != null && listOfLedger.length > 0 &&
                                                <tr>
                                                    <td><span className="text-gray"></span></td>
                                                    <td><span className="text-primary">{Math.ceil(totalRecords / pageSize) == pageNo ? 'Closing Balance' : 'Balance Carried Forward'}</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span className="text-primary">{Currency} {NumberFormat(closingBalance)}</span></td>
                                                </tr>
                                            }


                                        </>
                                }
                            </tbody>
                        </table>

                        {
                            totalRecords > 10 &&
                            <BsPagination
                                length={listOfLedger.length}
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                                onPageSizeChange={handlePageSizeChange}
                            />
                        }
                    </div>
                </div>
            </div >
        </>
    );
}

export default EStatement;