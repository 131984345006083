import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo/mag.svg";
import { NavLink, useNavigate } from "react-router-dom";
import {
    WalletIcon,
    HomeIcon,
    ArrowTrendingUpIcon,
    FingerPrintIcon,
    ArrowLeftStartOnRectangleIcon,
    LockClosedIcon,
    SunIcon,
    ClipboardDocumentListIcon,
    DevicePhoneMobileIcon,
    SparklesIcon,

} from "@heroicons/react/24/outline";
import { useLocation } from 'react-router-dom';
import {
    MoonIcon
} from "@heroicons/react/24/solid";
import Notifications from "./Notifications";
import Config from "../../../Config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

function BusinessHeader(props) {
    const Name = localStorage.getItem("Full_Name");
    const Email_Address = localStorage.getItem("Email_Address");
    const [MemberPhoto, setMemberPhoto] = useState(localStorage.getItem("Img_File"));
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const Currency = localStorage.getItem("Currency");


    let navigate = useNavigate();

    // show active class on menu
    const currentLocation = useLocation();
    const isMenuItemActive = (path) => {
        return currentLocation.pathname.startsWith(path);
    };

    const SignOut = () => {
        localStorage.clear();
        navigate("/");
    };

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        localStorage.setItem("theme", newTheme);
        setTheme(newTheme);
        document.documentElement.setAttribute('data-bs-theme', newTheme);
    }


    useEffect(() => {
        document.documentElement.setAttribute('data-bs-theme', theme);
    }, [theme])


    const handleClick = () => {

        var business_header = document.getElementById("business_header_wrap");
        var overlay = document.getElementById("overlaymenu");
        var menubutton = document.getElementById("menubutton");
        var body = document.getElementById("body");


        if (overlay.classList.length === 0 && !menubutton.classList.contains('active')) {
            business_header.classList.add('active');
            overlay.classList.add('active');
            menubutton.classList.add('active');
            body.classList.add('overflow-hidden');
        } else {
            business_header.classList.remove('active');
            overlay.classList.remove('active');
            menubutton.classList.remove('active');
            body.classList.remove('overflow-hidden');

        }

        window.addEventListener('click', function (e) {
            if (document.getElementById('business_header_wrap').contains(e.target)) {
                business_header.classList.remove('active');
                overlay.classList.remove('active');
                menubutton.classList.remove('active');
                body.classList.remove('overflow-hidden');
            }
        });
    };
    const handleCurrency = (CurrencyName) => {

        const data = {

            UserID: UserID,
            AccessKey: AccessKey,
            Selected_Currency: CurrencyName
        }
        var api_config = {
            method: 'post',
            url: Config.base_url + 'Currency/UpdateCurrency',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(api_config)
            .then(function (response) {

                if (response.data.status_code == 1) {
                    localStorage.setItem("Currency", CurrencyName);
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
            .catch(function (error) {
                localStorage.setItem("Currency", 'AAA');
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    return (
        <>
            <ToastContainer />
            <header className="header">
                <NavLink to={`/partner/dashboard`} className="brand">
                    <img src={logo} />
                </NavLink>
                <div id="business_header_wrap" className={`header-wrap`}>
                    <ul className="header-menu">
                        <li className="header-menu-item">
                            <NavLink to={`/partner/dashboard`} className="header-menu-link" active aria-current="page">
                                <HomeIcon />
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="header-menu-item">
                            <NavLink to={`/partner/packages`} className="header-menu-link">
                                <DevicePhoneMobileIcon />
                                <span>Packages</span>
                            </NavLink>
                        </li>

                        <li className="header-menu-item">
                            <NavLink to={`/partner/wallet`} className="header-menu-link">
                                <WalletIcon />
                                <span>Wallets</span>
                            </NavLink>
                        </li>
                        <li className="header-menu-item">
                            <NavLink to={`/partner/rewards`} className="header-menu-link">
                                <SparklesIcon />
                                <span>Rewards</span>
                            </NavLink>
                        </li>
                        <li className="header-menu-item">
                            <NavLink to="/partner/kyc" className="header-menu-link">
                                <FingerPrintIcon />
                                <span>Kyc</span>
                            </NavLink>
                        </li>

                        {/* <li className="header-menu-item">
                        <NavLink to="/partner/foundation" className="header-menu-link">
                            <BuildingOffice2Icon />
                            <span>Foundation</span>
                        </NavLink>
                    </li> */}
                        <li className="header-menu-item">
                            <NavLink to="/partner/statement" className="header-menu-link">
                                <ArrowTrendingUpIcon />
                                <span>Statements</span>
                            </NavLink>
                        </li>
                        <li className="header-menu-item">
                            <NavLink to="/partner/transactions/membership" className={`header-menu-link ${isMenuItemActive('/partner/transactions') ? 'active' : ''}`}>
                                <ClipboardDocumentListIcon />
                                <span>Transactions</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="header-info">
                    <Notifications />
                    <div className="dropdown profile-menu">
                        <button className="dropdown-toggle profile-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={Config.img_path + MemberPhoto} alt="Profile Photo" />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="profile-info">
                                <div className="info">
                                    <h1>{Name}</h1>
                                    <p>{Email_Address}</p>
                                </div>
                                <div className="profile">
                                    <img src={Config.img_path + MemberPhoto} alt="Profile Photo" />
                                </div>
                            </div>
                            <li>
                                <NavLink to={`/partner/kyc`}>
                                    <label htmlFor="">My Profile</label>
                                    <div className="level d-none">LEVEL 3</div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/partner/profile/security'}>
                                    <label htmlFor="">Security</label>
                                    <LockClosedIcon />
                                </NavLink>

                            </li>
                            <li>
                                <label htmlFor="">Theme</label>
                                <div className="mode">
                                    <button className={`theme ${theme == "light" ? 'active sun' : ''}`} onClick={toggleTheme}>
                                        <SunIcon />
                                    </button>
                                    <button className={`theme ${theme == "dark" ? 'active moon' : ''}`} onClick={toggleTheme}>
                                        <MoonIcon />
                                    </button>
                                </div>
                            </li>
                            <li>
                                <label htmlFor="">Language</label>

                                <div className="dropdown sub-dropdown">
                                    <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <label htmlFor="">EN</label>
                                        <span className="material-symbols-outlined">
                                            expand_more
                                        </span>
                                    </button>
                                    {/* <ul className="dropdown-menu">
                                        <li>
                                            <span className="bs_label">Eng</span>
                                        </li>
                                        <li>
                                            <span className="bs_label">Urdu</span>
                                        </li>
                                    </ul> */}
                                </div>
                            </li>
                            <li>
                                <label htmlFor="">Currency</label>
                                <div className="dropdown sub-dropdown">
                                    <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <label htmlFor="">{Currency}</label>
                                        <span className="material-symbols-outlined">
                                            expand_more
                                        </span>
                                    </a>

                                    {/* <ul className="dropdown-menu ">
                                        <li onClick={(CurrencyName) => handleCurrency('PKR')}>
                                            <span className="bs_label">PKR</span>
                                        </li>
                                        <li onClick={(CurrencyName) => handleCurrency('USD')}>
                                            <span className="bs_label">USD</span>
                                        </li>
                                        <li onClick={(CurrencyName) => handleCurrency('AED')}>
                                            <span className="bs_label">AED</span>
                                        </li>
                                    </ul>  */}
                                </div>
                            </li>
                            <li>
                                <NavLink className="sign-out" onClick={SignOut}>
                                    <span className="bs_label">Logout</span>
                                    <ArrowLeftStartOnRectangleIcon />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="menu">
                        <svg id="menubutton" className={`ham ham3`} onClick={handleClick} viewBox="0 0 100 100" width="80">
                            <path
                                className="line top"
                                d="m 70,33 h -40 c -11.092231,0 11.883874,13.496726 -3.420361,12.956839 -0.962502,-2.089471 -2.222071,-3.282996 -4.545687,-3.282996 -2.323616,0 -5.113897,2.622752 -5.113897,7.071068 0,4.448316 2.080609,7.007933 5.555839,7.007933 2.401943,0 2.96769,-1.283974 4.166879,-3.282995 2.209342,0.273823 4.031294,1.642466 5.857227,-0.252538 v -13.005715 16.288404 h 7.653568" />
                            <path
                                className="line middle"
                                d="m 70,50 h -40 c -5.6862,0 -8.534259,5.373483 -8.534259,11.551069 0,7.187738 3.499166,10.922274 13.131984,10.922274 11.021777,0 7.022787,-15.773343 15.531095,-15.773343 3.268142,0 5.177031,-2.159429 5.177031,-6.7 0,-4.540571 -1.766442,-7.33533 -5.087851,-7.326157 -3.321409,0.0092 -5.771288,2.789632 -5.771288,7.326157 0,4.536525 2.478983,6.805271 5.771288,6.7" />
                            <path
                                className="line bottom"
                                d="m 70,67 h -40 c 0,0 -3.680675,0.737051 -3.660714,-3.517857 0.02541,-5.415597 3.391687,-10.357143 10.982142,-10.357143 4.048418,0 17.88928,0.178572 23.482143,0.178572 0,2.563604 2.451177,3.403635 4.642857,3.392857 2.19168,-0.01078 4.373905,-1.369814 4.375,-3.392857 0.0011,-2.023043 -1.924401,-2.589191 -4.553571,-4.107143 -2.62917,-1.517952 -4.196429,-1.799562 -4.196429,-3.660714 0,-1.861153 2.442181,-3.118811 4.196429,-3.035715 1.754248,0.0831 4.375,0.890841 4.375,3.125 2.628634,0 6.160714,0.267857 6.160714,0.267857 l -0.178571,-2.946428 10.178571,0 -10.178571,0 v 6.696428 l 8.928571,0 -8.928571,0 v 7.142858 l 10.178571,0 -10.178571,0" />
                        </svg>
                    </div>
                </div>
            </header>
        </>

    );
}

export default BusinessHeader;