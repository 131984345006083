import React, { useState, useEffect, useRef } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AdminRightsCheck from "../AdminRightsCheck";
import { Editor } from "@tinymce/tinymce-react";
import { ArrowUpTrayIcon, TrashIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import { Cropper } from "react-cropper";

const AddPromo = () => {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));

  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  const [BannerTitle, setBannerTitle] = useState('');

  const [Banner_URL, setBanner_URL] = useState('');
  const [ActivityPath, setActivityPath] = useState('');

  const [SortOrder, setSortOrder] = useState('0');


  // loadings

  const [cropperData, setCropperData] = useState([
    { img: "", selected: false, cropper: null, label: "Upload Banner Image", aspectRatio: 19 / 12 },
  ]);



  let navigate = useNavigate();


  const AddPromo = () => {
    document.title = "Mag Holding Admin";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Banner_Title: BannerTitle,
      Banner_URL: Banner_URL,
      ActivityPath: ActivityPath,
      Sort_Order: SortOrder,



      ...cropperData.reduce((acc, cropperItem, index) => {
        if (cropperItem.selected) {
          if (index === cropperData.length - 1) {
            acc['Banner_Image'] = cropperItem.cropper.getCroppedCanvas().toDataURL().substring(22);
          } else {
            acc[`SliderImage${index + 1}`] = cropperItem.cropper.getCroppedCanvas().toDataURL().substring(22);
          }
        }
        return acc;
      }, {}),
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/AddBanner",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {

        if (response.data.status_code === 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);

          navigate('/admin/promo/manage-promo');
        }
        else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key." || response.data.status_code === 2) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate('/admin/login')
        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

      })
      .catch(function (error) {
      });
  };

  const MAX_IMAGE_SIZE = (1024 * 2) * 1024; // 2MB in bytes

  const handleImageChange = (index, file) => {
    if (file.size > MAX_IMAGE_SIZE) {
      toast.error("Image size must be less than 2 MB", { position: toast.POSITION.TOP_RIGHT });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setCropperData(prev => {
        const updated = [...prev];
        updated[index].img = reader.result;
        updated[index].selected = true;
        return updated;
      });
    };
    reader.readAsDataURL(file);
  };

  const handleDrop = (index, event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) handleImageChange(index, file);
  };

  const handleFileChange = (index, event) => {
    const file = event.target.files[0];
    if (file) handleImageChange(index, file);
  };



  const handleFileDelete = (index) => {
    setCropperData(prev => {
      const updated = [...prev];
      updated[index].img = ""; // Clear the image
      updated[index].selected = false; // Set selected to false
      updated[index].cropper = null; // Clear the cropper instance if necessary
      return updated;
    });
  };



  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <div className="admin-content">

        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Add Promo
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to={`/admin/promo/manage-promo`}
                className="button button-primary text-white ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                Manage Promo
                <i className="ri-menu-line ms-3"></i>
              </NavLink>
            </div>
          </div>
        </div>

        <div>
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row align-items-end p-4 gy-3">
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Banner Title</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={BannerTitle}
                            onChange={(e) => setBannerTitle(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Banner URL</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={Banner_URL}
                            onChange={(e) => setBanner_URL(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Activity Path</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={ActivityPath}
                            onChange={(e) => setActivityPath(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Sort Order</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={SortOrder}
                            onChange={(e) => setSortOrder(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className="col-md-12">
                    <ul className="kyc-upload">
                      {cropperData.map((cropperItem, index) => (
                        <li key={index} style={{ width: '100%' }}>
                          <div className={`kyc-img-card w-100 ${index < 2 ? 'white' : 'blue'} mb-3`}>
                            <div className="card-inner">
                              <div
                                className={`upload-box ${cropperItem.selected ? 'dragFront' : ''}`}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleDrop(index, e)}
                              >
                                <input
                                  accept="image/png"
                                  type="file"
                                  onChange={(e) => handleFileChange(index, e)}
                                />

                                {cropperItem.selected ? (
                                  <>
                                    <Cropper
                                      style={{ marginTop: '-10px' }}
                                      // aspectRatio={cropperItem.aspectRatio}
                                      zoomTo={0}
                                      preview=".img-preview"
                                      src={cropperItem.img}
                                      dragMode="move"
                                      viewMode={2}
                                      minCropBoxHeight={10}
                                      minCropBoxWidth={10}
                                      background={false}
                                      responsive={true}
                                      autoCropArea={1}
                                      checkOrientation={false}
                                      onInitialized={(instance) => {
                                        setCropperData(prev => {
                                          const updated = [...prev];
                                          updated[index].cropper = instance;
                                          return updated;
                                        });
                                      }}
                                      guides={true}
                                    />

                                    <div className="delete" onClick={(e) => handleFileDelete(index, e)}>
                                      <TrashIcon />
                                    </div>
                                  </>
                                ) : (
                                  <label className='thumnail'>
                                    <ViewfinderCircleIcon />
                                    <p>{cropperItem.label}</p>
                                  </label>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-3 offset-md-9 text-center offset-md-2">
                    {
                      loading ?
                        <>
                          <Spinner animation="border" role="status"></Spinner>
                        </>
                        :
                        <button
                          className="button w-100 button-primary"
                          onClick={AddPromo}
                        >
                          Add Banner
                        </button>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddPromo