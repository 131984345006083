import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline";
import React from "react";
import money from "../../assets/images/partner/reward/200k.png";
import mobile from "../../assets/images/partner/reward/mobile.png";
import honda_70 from "../../assets/images/partner/reward/honda_70.png";
import honda_125 from "../../assets/images/partner/reward/honda_125.png";
import milion from "../../assets/images/partner/reward/1milion.png";
import ki_pic from "../../assets/images/partner/reward/ki_pic.png";
import civic from "../../assets/images/partner/reward/civic.png";
import prdo from "../../assets/images/partner/reward/prdo.png";
import vill from "../../assets/images/partner/reward/vill.png";



import Pearl from "../../assets/images/partner/Ranks/Pearl-md.png";
import Pearl_sm from "../../assets/images/partner/Ranks/Pearl-sm.png";
import Emerald from "../../assets/images/partner/Ranks/Emerald-md.png";
import Emerald_sm from "../../assets/images/partner/Ranks/Emerald-sm.png";
import Sapphire from "../../assets/images/partner/Ranks/Sapphire-md.png";
import Sapphire_sm from "../../assets/images/partner/Ranks/Sapphire-sm.png";
import Gold from "../../assets/images/partner/Ranks/Gold-md.png";
import Gold_sm from "../../assets/images/partner/Ranks/Gold-sm.png";
import BlackGold from "../../assets/images/partner/Ranks/BlackGold-md.png";
import BlackGold_sm from "../../assets/images/partner/Ranks/BlackGold-sm.png";
import GoldKing from "../../assets/images/partner/Ranks/GoldKing-md.png";
import GoldKing_sm from "../../assets/images/partner/Ranks/GoldKing-sm.png";
import Diamond from "../../assets/images/partner/Ranks/Diamond-md.png";
import Diamond_sm from "../../assets/images/partner/Ranks/Diamond-sm.png";
import DiamondLion from "../../assets/images/partner/Ranks/DiamondLion-md.png";
import DiamondLion_sm from "../../assets/images/partner/Ranks/DiamondLion-sm.png";
import DiamondKing from "../../assets/images/partner/Ranks/DiamondKing-md.png";
import DiamondKing_sm from "../../assets/images/partner/Ranks/DiamondKing-sm.png";
import BlackDiamond from "../../assets/images/partner/Ranks/BlackDiamond-md.png";
import BlackDiamond_sm from "../../assets/images/partner/Ranks/BlackDiamond-sm.png";

function BusinessReward(props) {
    return (
        <>
            <div className="section-title">Ranks & Rewards</div>
            <div className="row gy-3 pb-5">
                <div className="col-md-6 col-ms-12">
                    <div className="reward-card reward-completed">
                        <div className="content">
                            <div className="rank-head">
                                <div className="rank-info">
                                    <img src={Sapphire} alt="Sapphire" />
                                    <span>Sapphire Rank</span>
                                </div>
                                <div className="criteria">
                                    <span>Criteria</span>
                                    <ul>
                                        <li><span>1st Line :</span> <label htmlFor="">1,000 GPV</label></li>
                                        <li><span>2nd Line :</span><label htmlFor=""> 1,200 GPV</label></li>
                                    </ul>
                                </div>
                                <LockOpenIcon />
                            </div>
                            <div className="border">
                                <img src={Pearl_sm} alt="Pearl" />
                            </div>
                            <div className="price">
                                <label htmlFor="">Reward</label>
                                <h3>20,000 PKR</h3>
                            </div>
                        </div>
                        <div className="thumnail">
                            <img src={money} alt="money" />
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-ms-12">
                    <div className="reward-card active">
                        <div className="content">
                            <div className="rank-head">
                                <div className="rank-info">
                                    <img src={Emerald} alt="Emerald" />
                                    <span>Emerald Rank</span>
                                </div>
                                <div className="criteria">
                                    <span>Criteria</span>
                                    <ul>
                                        <li><span>1st Line :</span> <label htmlFor="">1,500 GPV</label></li>
                                        <li><span>2nd Line :</span><label htmlFor=""> 2,000 GPV</label></li>
                                        <li><span>3rd Line :</span><label htmlFor=""> 3,000 GPV</label></li>
                                    </ul>
                                </div>
                                <LockClosedIcon />
                            </div>
                            <div className="border">
                                <img src={Emerald_sm} alt="Emerald" />
                            </div>
                            <div className="price">
                                <label htmlFor="">Reward</label>
                                <h3>Android Mobile <span>(Value: 100k PKR)</span></h3>
                            </div>
                        </div>
                        <div className="thumnail">
                            <img src={mobile} alt="mobile" />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-ms-12">
                    <div className="reward-card reward-pending">
                        <div className="content">
                            <div className="rank-head">
                                <div className="rank-info">
                                    <img src={Gold} alt="Gold" />
                                    <span>Gold Rank</span>
                                </div>
                                <div className="criteria">
                                    <span>Criteria</span>
                                    <ul>
                                        <li><span>1st Line :</span> <label htmlFor="">3,000 GPV</label></li>
                                        <li><span>2nd Line :</span><label htmlFor=""> 4,000 GPV</label></li>
                                        <li><span>3rd Line :</span><label htmlFor=""> 7,000 GPV</label></li>
                                    </ul>
                                </div>
                                <LockClosedIcon />
                            </div>
                            <div className="border">
                                <img src={Gold_sm} alt="Gold" />
                            </div>
                            <div className="price">
                                <label htmlFor="">Reward</label>
                                <h3>CD-70 Motorcycle (Value: 150k PKR)</h3>
                            </div>
                        </div>
                        <div className="thumnail">
                            <img src={honda_70} alt="honda_70" />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-ms-12">
                    <div className="reward-card reward-pending">
                        <div className="content">
                            <div className="rank-head">
                                <div className="rank-info">
                                    <img src={BlackGold} alt="BlackGold" />
                                    <span>Black Gold Rank</span>
                                </div>
                                <div className="criteria">
                                    <span>Criteria</span>
                                    <ul>
                                        <li><span>1st Line :</span> <label htmlFor="">5,000 GPV</label></li>
                                        <li><span>2nd Line :</span><label htmlFor=""> 7,000 GPV</label></li>
                                        <li><span>3rd Line :</span><label htmlFor=""> 10,000 GPV</label></li>
                                        <li><span>4rd Line :</span> <label htmlFor="">8,000 GPV</label></li>
                                    </ul>
                                </div>
                                <LockClosedIcon />
                            </div>
                            <div className="border">
                                <img src={BlackGold_sm} alt="BlackGold_sm" />
                            </div>
                            <div className="price">
                                <label htmlFor="">Reward</label>
                                <h3>Honda CD-125 (Value: 230k PKR)</h3>
                            </div>
                        </div>
                        <div className="thumnail">
                            <img src={honda_125} alt="honda_125" />
                            
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-ms-12">
                    <div className="reward-card reward-pending">
                        <div className="content">
                            <div className="rank-head">
                                <div className="rank-info">
                                    <img src={GoldKing} alt="GoldKing" />
                                    <span>Gold King Rank</span>
                                </div>
                                <div className="criteria">
                                    <span>Criteria</span>
                                    <ul>
                                        <li><span>1st Line :</span> <label htmlFor="">18,000 GPV</label></li>
                                        <li><span>2nd Line :</span><label htmlFor=""> 25,000 GPV</label></li>
                                        <li><span>3rd Line :</span><label htmlFor=""> 60,000 GPV</label></li>
                                        <li><span>4th Line :</span><label htmlFor=""> 15,000 GPV</label></li>
                                    </ul>
                                </div>
                                <LockClosedIcon />
                            </div>
                            <div className="border">
                                <img src={GoldKing_sm} alt="GoldKing_sm" />
                            </div>
                            <div className="price">
                                <label htmlFor="">Reward</label>
                                <h3>One Million Cash</h3>
                            </div>
                        </div>
                        <div className="thumnail">
                            <img src={milion} alt="1milion" />
                            
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-ms-12">
                    <div className="reward-card reward-pending ">
                        <div className="content">
                            <div className="rank-head">
                                <div className="rank-info">
                                    <img src={Diamond} alt="Diamond" />
                                    <span>Diamond Rank</span>
                                </div>
                                <div className="criteria">
                                    <span>Criteria</span>
                                    <ul>
                                        <li><span>1st Line :</span> <label htmlFor="">100,000 GPV</label></li>
                                        <li><span>2nd Line :</span><label htmlFor=""> 180,000 GPV</label></li>
                                        <li><span>3rd Line :</span><label htmlFor=""> 80,000 GPV</label></li>
                                        <li><span>4th Line :</span><label htmlFor=""> 40,000 GPV</label></li>
                                    </ul>
                                </div>
                                <LockClosedIcon />
                            </div>
                            <div className="border">
                                <img src={Diamond_sm} alt="Diamond" />
                            </div>
                            <div className="price">
                                <label htmlFor="">Reward</label>
                                <h3>Kia Picanto (Value: 35 Lac PKR)</h3>
                            </div>
                        </div>
                        <div className="thumnail">
                           <img src={ki_pic} alt="ki_pic" />
                        </div>
                    </div>
                </div>


               
                <div className="col-md-6 col-ms-12">
                    <div className="reward-card reward-pending">
                        <div className="content">
                            <div className="rank-head">
                                <div className="rank-info">
                                    <img src={DiamondLion} alt="DiamondLion" />
                                    <span>Diamond Lion Rank</span>
                                </div>
                                <div className="criteria">
                                    <span>Criteria</span>
                                    <ul>
                                        <li><span>1st Line :</span> <label htmlFor="">400,000 GPV</label></li>
                                        <li><span>2nd Line :</span><label htmlFor=""> 300,000 GPV</label></li>
                                        <li><span>3rd Line :</span><label htmlFor=""> 200,000 GPV</label></li>
                                        <li><span>4th Line :</span><label htmlFor=""> 100,000 GPV</label></li>
                                        <span>-- OR --</span>
                                        <li>Double GPV in Three Lines</li>
                                    </ul>
                                </div>
                                <LockClosedIcon />
                            </div>
                            <div className="border">
                                <img src={DiamondLion_sm} alt="DiamondLion_sm" />
                            </div>
                            <div className="price">
                                <label htmlFor="">Reward</label>
                                <h3>Honda Civic (Value: 85 Lac PKR)</h3>
                            </div>
                        </div>
                        <div className="thumnail">
                            <img src={civic} alt="civic" />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-ms-12">
                    <div className="reward-card reward-pending">
                        <div className="content">
                            <div className="rank-head">
                                <div className="rank-info">
                                    <img src={DiamondKing} alt="DiamondKing" />
                                    <span>Diamond King Rank</span>
                                </div>
                                <div className="criteria">
                                    <span>Criteria</span>
                                    <ul>
                                        <li><span>1st Line :</span> <label htmlFor="">1,500,000 GPV</label></li>
                                        <li><span>2nd Line :</span><label htmlFor=""> 500,000 GPV</label></li>
                                        <li><span>3rd Line :</span><label htmlFor=""> 300,000 GPV</label></li>
                                        <li><span>4th Line :</span><label htmlFor=""> 200,000 GPV</label></li>
                                        <span>-- OR --</span>
                                        <li>Double GPV in Two Lines</li>
                                    </ul>
                                </div>
                                <LockClosedIcon />
                            </div>
                            <div className="border">
                                <img src={DiamondKing_sm} alt="DiamondKing_sm" />
                            </div>
                            <div className="price">
                                <label htmlFor="">Reward</label>
                                <h3>Toyota Prado (Value: 25M PKR)</h3>
                            </div>
                        </div>
                        <div className="thumnail">
                            <img src={prdo} alt="prdo" />
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="reward-card reward-pending last-reward-card">
                        <div className="content">
                            <div className="rank-head">
                                <div className="rank-info">
                                    <img src={BlackDiamond} alt="BlackDiamond" />
                                    <span>Black Diamond Rank</span>
                                </div>
                                <div className="criteria">
                                    <span>Criteria</span>
                                    <ul>
                                        <li><span>1st Line :</span> <label htmlFor="">4,000,000 GPV</label></li>
                                        <li><span>2nd Line :</span><label htmlFor=""> 2,000,000 GPV</label> </li>
                                        <li><span>3rd Line :</span><label htmlFor=""> 1,000,000 GPV</label></li>
                                        <li><span>4th Line :</span><label htmlFor=""> 500,000 GPV</label></li>
                                        <span>-- OR --</span>
                                        <li>Double GPV in Two Lines</li>
                                    </ul>
                                </div>
                                <LockClosedIcon />
                            </div>
                            <div className="border">
                                <img src={BlackDiamond_sm} alt="BlackDiamond_sm" />
                            </div>
                            <div className="price">
                                <label htmlFor="">Reward</label>
                                <h3>2 Kanal Luxury Villa (Value: 80M PKR)</h3>
                            </div>
                        </div>
                        <div className="thumnail">
                            <img src={vill} alt="vill" />
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default BusinessReward;