import React, { useState, useEffect, useRef } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AdminRightsCheck from "../AdminRightsCheck";
import { Editor } from "@tinymce/tinymce-react";
import { ArrowUpTrayIcon, TrashIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import { Cropper } from "react-cropper";

const UpdatePackage = () => {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));

  const [showFilters, setShowFilters] = useState(true);
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfRecord, setListOfRecord] = useState([]);

  const [Name, setName] = useState('');
  const [Amount, setAmount] = useState('');
  const [ROI, setROI] = useState('');
  const [PackageType, setPackageType] = useState('');
  const [NoofDays, setNoofDays] = useState('');
  const [TagLine, setTagLine] = useState('');
  const [DirectBonus, setDirectBonus] = useState('');
  const [DailyProfitReturn, setDailyProfitReturn] = useState('');
  const [MonthlyProfit, setMonthlyProfit] = useState('');
  const [TotalProfitReturn, setTotalProfitReturn] = useState('');
  const [ProductDelivery, setProductDelivery] = useState('');
  const [SortOrder, setSortOrder] = useState('');
  const [CashPromoPercentage, setCashPromoPercentage] = useState('');
  const [CashPromoFixed, setCashPromoFixed] = useState('');
  const [GetPackage, setGetPackage] = useState({});
  const [ShowPackage, setShowPackage] = useState();
  const [Details, setDetails] = useState('');
  const [RibbonColor, setRibbonColor] = useState('');
  const [RibbonText, setRibbonText] = useState('');
  // loadings
  const [LoadingPackage, setLoadingPackage] = useState(false);


  let navigate = useNavigate();
  let params = useParams();



  const [cropperData, setCropperData] = useState([
    { img: "", update: false, selected: false, cropper: null, label: "Upload Slider Image 1", aspectRatio: 19 / 12 },
    { img: "", update: false, selected: false, cropper: null, label: "Upload Slider Image 2", aspectRatio: 19 / 12 },
    { img: "", update: false, selected: false, cropper: null, label: "Upload Slider Image 3", aspectRatio: 19 / 12 },
    { img: "", update: false, selected: false, cropper: null, label: "Upload Slider Image 4", aspectRatio: 19 / 12 },
    { img: "", update: false, selected: false, cropper: null, label: "Upload Package Image", aspectRatio: 1 },
  ]);



  const DetailRef = useRef(null);
  const [Detail, setDetail] = useState("");
  const handleDetailChange = (content) => {
    setDetail(content);
  };

  const UpdatePackage = () => {
    document.title = "Package Setting Update";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: params.id,
      Name: Name,
      Amount: Amount,
      ROI: ROI,
      PackageType: PackageType,
      NoofDays: NoofDays,
      TagLine: TagLine,
      DirectBonus: DirectBonus,
      DailyProfitReturn: DailyProfitReturn,
      MonthlyProfit: MonthlyProfit,
      TotalProfitReturn: TotalProfitReturn,
      ProductDelivery: ProductDelivery,
      Details: Detail,
      SortOrder: SortOrder,
      ShowPackage: ShowPackage,
      RibbonColor: RibbonColor,
      RibbonText: RibbonText,
      CashPromoFixed: CashPromoFixed,
      CashPromoPercentage: CashPromoPercentage,

      ...cropperData.reduce((acc, cropperItem, index) => {
        if (cropperItem.selected) {
          if (index === cropperData.length - 1) {
            acc['Image'] = cropperItem.cropper.getCroppedCanvas().toDataURL().substring(22);
          } else {
            acc[`SliderImage${index + 1}`] = cropperItem.cropper.getCroppedCanvas().toDataURL().substring(22);
          }
        }
        return acc;
      }, {}),
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Package/UpdatePackageDetail",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setListOfRecord(response.data.ListofRecords);
          setLoading(false);
        }
        else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key." || response.data.status_code === 2) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

      })
      .catch(function (error) {
      });
  };


  useEffect(() => {

    setLoadingPackage(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Member_Type: MemberType,
      ID: params.id,
    };
    var api_config = {
      method: 'post',
      url: config.base_url + 'Package/GetPackagebyID',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setGetPackage(response.data.Package);
          setLoadingPackage(false);
          setName(response.data.Package.Name);
          setAmount(response.data.Package.Amount);
          setROI(response.data.Package.ROI);
          setPackageType(response.data.Package.PackageType);
          setNoofDays(response.data.Package.NoofDays);
          setTagLine(response.data.Package.TagLine);
          setDirectBonus(response.data.Package.DirectBonus);
          setDailyProfitReturn(response.data.Package.DailyProfitReturn);
          setMonthlyProfit(response.data.Package.MonthlyProfit);
          setTotalProfitReturn(response.data.Package.TotalProfitReturn);
          setProductDelivery(response.data.Package.ProductDelivery);
          setDetails(response.data.Package.Details);
          setSortOrder(response.data.Package.SortOrder);
          setShowPackage(response.data.Package?.ShowPackage);
          setRibbonColor(response.data.Package?.RibbonColor);
          setRibbonText(response.data.Package?.RibbonText);
          setCashPromoPercentage(response.data.Package?.CashPromoPercentage);
          setCashPromoFixed(response.data.Package?.CashPromoFixed);

          const imageNames = response.data.SliderImages; // This is the array of image names
          const imagePathBase = config.root + 'Images/Packages/';

          const updatedCropperData = cropperData.map((item, index) => {
            if (index < imageNames.length) {
              return {
                img: imagePathBase + imageNames[index], // Concatenate base path with image name
                selected: true,
                label: `Upload Package Image ${index + 1}`
              };
            }
            return item; // Return unchanged item if no corresponding image
          });

          setCropperData(updatedCropperData);


        }

        else {
          setLoadingPackage(false);
        }
      })
      .catch(function (error) {
      });
  }, []);

  const handleCheckboxChange = (e) => {
    setShowPackage(e.target.checked);
  };

  const MAX_IMAGE_SIZE = (1024 * 2) * 1024; // 2MB in bytes

  const handleImageChange = (index, file) => {
    if (file.size > MAX_IMAGE_SIZE) {
      toast.error("Image size must be less than 2 MB", { position: toast.POSITION.TOP_RIGHT });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setCropperData(prev => {
        const updated = [...prev];
        updated[index].img = reader.result;
        updated[index].selected = true;
        updated[index].update = true;
        return updated;
      });
    };
    reader.readAsDataURL(file);
  };

  const handleDrop = (index, event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) handleImageChange(index, file);
  };

  const handleFileChange = (index, event) => {
    const file = event.target.files[0];
    if (file) handleImageChange(index, file);
  };



  const handleFileDelete = (index) => {
    setCropperData(prev => {
      const updated = [...prev];
      updated[index].img = ""; // Clear the image
      updated[index].selected = false; // Set selected to false
      updated[index].update = true; // Set selected to false
      updated[index].cropper = null; // Clear the cropper instance if necessary
      return updated;
    });
  };


  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <div className="admin-content">

        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Update Packages
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to={`/admin/package/manage-package`}
                className="button button-primary text-white ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                Manage Package
                <i className="ri-menu-line ms-3"></i>
              </NavLink>
            </div>
          </div>
        </div>

        <div>
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row align-items-end p-4 gy-3">
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Name</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Amount</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={Amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">ROI</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={ROI}
                            onChange={(e) => setROI(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Package Type</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={PackageType}
                            onChange={(e) => setPackageType(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">No of Days</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={NoofDays}
                            onChange={(e) => setNoofDays(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Tag Line</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={TagLine}
                            onChange={(e) => setTagLine(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Direct Bonus</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={DirectBonus}
                            onChange={(e) => setDirectBonus(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Daily Profit Return</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={DailyProfitReturn}
                            onChange={(e) => setDailyProfitReturn(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Monthly Profit</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={MonthlyProfit}
                            onChange={(e) => setMonthlyProfit(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Total Profit Return</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={TotalProfitReturn}
                            onChange={(e) => setTotalProfitReturn(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Product Delivery</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={ProductDelivery}
                            onChange={(e) => setProductDelivery(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Cash Promo Percentage</label>
                        <div className="inputwrap">
                          <input
                            type="number"
                            className="form-control"
                            value={CashPromoPercentage}
                            onChange={(e) => {
                              setCashPromoPercentage(e.target.value);
                              setCashPromoFixed(0)
                            }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Cash Promo Fixed</label>
                        <div className="inputwrap">
                          <input
                            type="number"
                            className="form-control"
                            value={CashPromoFixed}
                            onChange={(e) => {
                              setCashPromoFixed(e.target.value);
                              setCashPromoPercentage(0);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Sort Order</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={SortOrder}
                            onChange={(e) => setSortOrder(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Ribbon Color</label>
                        <div className="inputwrap">
                          <input
                            style={{ width: '30px', height: '30px', padding: '0' }}
                            type="color"
                            className="form-control"
                            value={RibbonColor}
                            onChange={(e) => setRibbonColor(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Ribbon Text</label>
                        <div className="inputwrap">
                          <input
                            style={{ width: '30px', height: '30px', padding: '0' }}
                            type="color"
                            className="form-control"
                            value={RibbonText}
                            onChange={(e) => setRibbonText(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <div className="form-check mt-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={ShowPackage}
                          onChange={handleCheckboxChange}
                          id="ShowPackage"
                        />
                        <label className="form-check-label" htmlFor="ShowPackage">
                          Show Package
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 text-center offset-md-2">
                    {
                      loading ?
                        <>
                          <Spinner animation="border" role="status"></Spinner>
                        </>
                        :
                        <button
                          className="button w-100 button-primary"
                          onClick={UpdatePackage}
                        >
                          Update Package
                        </button>
                    }

                  </div>
                  <div className="col-md-12">
                    <Editor
                      apiKey={config.tinyapikey}
                      init={{
                        plugins:
                          "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                        toolbar:
                          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                      }}
                      initialValue={Details}
                      onInit={(editor) => DetailRef.current = editor}
                      onEditorChange={handleDetailChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <ul className="kyc-upload">
                      {cropperData.map((cropperItem, index) => (
                        <li key={index} style={{ width: '48%' }}>
                          <div className={`kyc-img-card w-100 ${index < 2 ? 'white' : 'blue'} mb-3`}>
                            <div className="card-inner">
                              <div
                                className={`upload-box ${cropperItem.selected ? 'dragFront' : ''}`}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleDrop(index, e)}
                              >
                                <input
                                  accept="image/png"
                                  type="file"
                                  onChange={(e) => handleFileChange(index, e)}
                                />

                                {cropperItem.selected ? (
                                  <>
                                    <Cropper
                                      style={{ marginTop: '-10px' }}
                                      // aspectRatio={cropperItem.aspectRatio}
                                      zoomTo={0}
                                      preview=".img-preview"
                                      src={cropperItem.img}
                                      dragMode="move"
                                      viewMode={2}
                                      minCropBoxHeight={10}
                                      minCropBoxWidth={10}
                                      background={false}
                                      responsive={true}
                                      autoCropArea={1}
                                      checkOrientation={false}
                                      onInitialized={(instance) => {
                                        setCropperData(prev => {
                                          const updated = [...prev];
                                          updated[index].cropper = instance;
                                          return updated;
                                        });
                                      }}
                                      guides={true}
                                    />

                                    <div className="delete" onClick={(e) => handleFileDelete(index, e)}>
                                      <TrashIcon />
                                    </div>
                                  </>
                                ) : (
                                  <label className='thumnail'>
                                    <ViewfinderCircleIcon />
                                    <p>{cropperItem.label}</p>
                                  </label>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdatePackage