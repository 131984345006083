import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import config from "../../../Config";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import AdminRightsCheck from "../AdminRightsCheck";
const UpdateGroup = () => {
  let navigate = useNavigate();
  let params = useParams();

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [loading, setLoading] = useState(false);
  const [UserGroup, setUserGroup] = useState({});

  const [groupName, setgroupName] = useState("");
  const [Description, setDescription] = useState("");
  const [GroupID, setGroupID] = useState(params.id);

  const [OfficeAddress, setOfficeAddress] = useState("")
  const [OfficeContactNo, setOfficeContactNo] = useState("")
  const [OfficeLocation, setOfficeLocation] = useState("")


  useEffect(() => {
    document.title = "Mag Holdings Admin";

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: "Admin",
      ID: GroupID,
    };



    var api_config = {
      method: "post",
      url: config.base_url + "Security/GetUserGroupByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setUserGroup(response.data.UserGroup);
          setgroupName(response.data.UserGroup.Group_Name)
          setDescription(response.data.UserGroup.Description)
        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: "Admin",
      ID: GroupID,
      Group_Name: groupName,
      Description: Description,
      OfficeAddress: OfficeAddress,
      OfficeContactNo: OfficeContactNo,
      OfficeLocation: OfficeLocation

    };
    var api_config = {
      method: 'post',
      url: config.base_url + 'Security/UpdateSecurityGroup',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {

          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
          setTimeout(() => {
            navigate("/admin/security-dashboard/manage-groups")

          }, 2000)

        }
        else if (response.data.status_code == 0 && response.data.status_message == "Invalid Session for Update.") {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }

        setLoading(false);


      })
      .catch(function (error) {
        setLoading(false);
        toast.error('Network Error..', {
          position: toast.POSITION.TOP_RIGHT
        });
      });

  };

  return (
    <>
      <ToastContainer />
      <AdminRightsCheck />
      <div className="admin-content">
        <div className="row align-items-center mb-3 gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Update Group
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <NavLink to="/admin/security-dashboard/manage-groups">
              <button type="button" className="button button-outline-bs">
                Back
              </button>
            </NavLink>
          </div>
        </div>

        <div className="card card-table form_default">
          <div className="card-inner p-4 form-group">
            <form onSubmit={handleSubmit}>
              <div className="row gy-3">
                <div className="col-md-8">
                  <h6 className="">Group Name</h6>
                  <div className="inputwrap">
                    <input
                      type="text"
                      className="form-control w-100"
                      defaultValue={UserGroup.Group_Name}
                      onChange={(e) => setgroupName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <h6 className="">Office Contact No</h6>
                  <div className="inputwrap">
                    <input
                      type="text"
                      className="form-control w-100"
                      onChange={(e) => { setOfficeContactNo(e.target.value) }}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <h6 className="">Office Address</h6>
                  <div className="inputwrap">
                    <input
                      type="text"
                      className="form-control w-100"
                      onChange={(e) => { setOfficeAddress(e.target.value) }}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <h6 className="">Office Location</h6>
                  <div className="inputwrap">
                    <input
                      type="text"
                      className="form-control w-100"
                      onChange={(e) => { setOfficeLocation(e.target.value) }}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <h6 className="">Description</h6>
                  <div className="inputwrap">
                    <input
                      type="text"
                      className="form-control w-100"
                      defaultValue={UserGroup.Description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-3 offset-md-9">
                  <button type="submit" className="button button-primary mt-4 w-100 input-height">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateGroup;
