import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import Config from "../../../Config";
import { NumberFormat, NumberFormat3D } from "../../Dashboard/General/Functions";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import TransactionMenu from "./TransactionMenu";
import PassiveMenu from "./PassiveMenu";
import BsPagination from "../BsPagination";
import { calculateColumnTotal } from "../../Common/Util";
import LoginCheck from "../General/LoginCheck";

function TreeIncome(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const Currency = localStorage.getItem("Currency");
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [ledgerOld, setLedgerOld] = useState([]);



    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    useEffect(() => {

        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Wallet/GetTreeBonus',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofROI);
                setLedgerOld(response.data.ListofROI);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                console.log(error);
            });





    }, [pageNo, pageSize]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };



    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {

            let sorted = [...listOfLedger].sort((a, b) => b.ID - a.ID);

            return (
                sorted.map((item, index) => {

                    return (
                        <tr key={index}>

                            <td>{moment(item.Date).format('DD/MM/YYYY')}</td>
                            <td>Level {item.RewardLevel}</td>
                            <td>{Currency} {NumberFormat(item.PackageAmount)}</td>
                            <td>{Currency} {NumberFormat(item.ROI)}</td>
                            <td>{item.Percentage}%</td>
                            <td>{Currency} {NumberFormat(item.Released)}</td>
                            <td>{Currency} {NumberFormat(item.Released)}</td>
                            
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="7">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }

    }






    return (
        <>
            <LoginCheck />

            <TransactionMenu />
            <div className="partner-table">
                <PassiveMenu />
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Level   </th>
                                <th>Total Package</th>
                                <th>Profit Return</th>
                                <th>Percentage</th>
                                <th>Royalty</th>
                                <th>Received</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    <>
                                        {renderLedger()}
                                        {
                                            listOfLedger != null && listOfLedger.length > 0 &&
                                            <tr>
                                                <th colSpan={6} className="text-end"><strong>Total:</strong></th>
                                                <td className=""><strong>{Currency} {NumberFormat(calculateColumnTotal(listOfLedger, 'Released'))}</strong></td>
                                            </tr>

                                        }

                                    </>
                            }

                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&
                       
                        <BsPagination
                            length={listOfLedger.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                          onPageSizeChange={(newPageSize) =>  { 
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />

                    }
                </div>
            </div>


        </>
    );
}

export default TreeIncome;