import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";

function UserPromoList(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [IsDelect, setIsDelect] = useState(false);


  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);


  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Mag Holdings Admin";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetBannersList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        
        } else if (
          response.data.status_code === 0 ||
          response.data.status_code === 2
        ) {
          setLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [pageNo, pageSize,IsDelect]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <>
            <tr key={index}>
              <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
              <td>{moment(item.Created_Date).format("DD/MM/YYYY HH:mm")}</td>
              <td>{item.Banner_Image}</td>
              <td>{item.Banner_Title}</td>
              <td>{item.Banner_URL}</td>
              <td>{item.Sort_Order}</td>
              <td>
                <ul
                  className="inline-action"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <NavLink
                      to={"/admin/promo/update-promo/" + item.ID}
                      className="primary "
                    >
                      <i className="ri-edit-line"></i>
                    </NavLink>
                  </li>
                  <li><Link className="red" onClick={(e) => DelectItem(item.ID)} type="button"><i className="ri-delete-bin-line"></i></Link></li>
                </ul>
              </td>
            </tr>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="10">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

 
  const DelectItem = (ID) => {
    if (window.confirm('Are you sure to delete the Promo')) {

      setIsDelect(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        ID: ID,
      };


      var api_config = {
        method: 'post',
        url: config.base_url + 'Users/DeleteBanner',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };


      axios(api_config)
        .then(function (response) {

          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsDelect(false);
          }
          else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsDelect(false);
          }
        })
        .catch(function (error) {
          toast.error(error.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

        });
    }
  };
  return (
    <>
      <AdminRightsCheck />

      <div className="admin-content">
        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">Manage Promo</div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to="/admin/Promo-dashboard">
                <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
              <NavLink to='/admin/promo/add-promo' className="button button-primary text-white ms-3">
                Add Promo
                <i className="ri-filter-3-line ms-3"></i>
              </NavLink>
            </div>
          </div>
        </div>



        <div className="card card-table mb-5 mt-3">
          <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Date</th>
                  <th>Banner Image</th>
                  <th>Banner Title</th>
                  <th>Banner URL</th>
                  <th>Sort Order</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="9">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>
                    {renderLedger()}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UserPromoList;
