import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import { ArrowLongLeftIcon, TrashIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import Spinner from 'react-bootstrap/Spinner';
import ReactTooltip from 'react-tooltip';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PAK from "../../../assets/images/partner/flag/pak_flag.png";
import QRCode from "react-qr-code";
import { ArrowLeftCircleIcon, ArrowLongRightIcon } from "@heroicons/react/24/outline";
import BankDepositWizard from "./BankDepositWizard";
import LoginCheck from "../General/LoginCheck";

function MoneyBankDeposit(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));

    const [loading, setLoading] = useState(false);

    const [validated, setValidated] = useState(false);
    const [amount, setAmount] = useState(0);
    const [Converted, setConverted] = useState(null);
    const [TargetCryptoCurrency, setTargetCryptoCurrency] = useState(null);
    const [EstimatedAmount, setEstimatedAmount] = useState(0);
    const [Order, setOrder] = useState({});
    const [step, setStep] = useState('2');
    const [rates, setRates] = useState([]);

    const [loadingSaveSlip, setLoadingSaveSlip] = useState(false);

    // wizard tabs active status
    const [active2, setActive2] = useState(true);
    const [active3, setActive3] = useState(false);
    const [active4, setActive4] = useState(false);

    // wizard completed tabs active status
    const [completed, setCompleted] = useState(true);
    const [completed2, setCompleted2] = useState(false);
    const [completed3, setCompleted3] = useState(false);
    const [completed4, setCompleted4] = useState(false);

    const [TransactionID, setTransactionID] = useState('');
    const [Comments, setComments] = useState('');
    const [ReceiptImg, setReceiptImg] = useState(null);


    const [imageSelected, setImageSelected] = useState(false);
    const [image, setImage] = useState('');


    const [cropper, setCropper] = useState();
    const [imageCropped, setImageCropped] = useState(false);


    const DeleteImage = () => {
        setImageSelected(false);
        setImage('');
        setCropper();
    }

    const [kyc, setkyc] = useState({});
    let navigate = useNavigate();

    const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 2MB in bytes

    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            setImageSelected(false)
            toast.error("Image size must be less than 5 MB", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        setLoading(true);
        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);
            var cleanerBase64 = cropData1.substring(22);
            setReceiptImg(cleanerBase64);


        }
    };

    useEffect(() => {
        if (imageCropped) {
            getCropData();
        }
    }, [imageCropped]);



    const [dragging, setDragging] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDrop = (event) => {

        event.preventDefault();
        setImageSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {

            if (file.size > MAX_IMAGE_SIZE) {

                setImageSelected(false)
                toast.error("Image size must be less than 5 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            // console.log(file.size);
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
                if (cropper) {
                    cropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    let params = useParams();

    useEffect(() => {
        if (params.amount) {
            setAmount(params.amount);
        }
    }, [params.amount]);

    useEffect(() => {
        var api_config = {
            method: "get",
            url: "https://api.metalpriceapi.com/v1/latest?api_key=7da24ec81b918bbe12effe29fb3a1b25&base=USD&currencies=PKR,AED,EUR,USD",
            headers: {
                "Content-Type": "application/json",
                'X-API-KEY': 'a3ae13b739a5177f04f978f8d00b3786'
            },
            //data: data
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setRates(response.data.rates);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, []);

    const NextStep3 = () => {
        setStep(3);
        setActive2(true);
        setCompleted2(true);
        setActive3(true);
    }
    const NextStep4 = () => {



        let base64_string = "";

        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);
            var cleanerBase64 = cropData1.substring(22);
            base64_string = cleanerBase64;


        }

        if (base64_string != "") {
            setLoadingSaveSlip(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                AmountUSD: '',
                Amount: amount,
                Transaction_ID: TransactionID,
                Comments: Comments,
                DepositSlip: base64_string,
            }
            // console.log(data)

            var api_config = {
                method: 'post',
                url: config.base_url + 'Deposit/AddBankDeposit',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {

                    // console.log(response.data);
                    if (response.data.status_code == 1) {
                        setLoadingSaveSlip(false);
                        setStep(4);
                        setActive2(true);
                        setCompleted2(true);
                        setActive3(true);
                        setCompleted3(true);
                        setActive4(true);
                        setCompleted4(true);
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch(function (error) {
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT,
                        //onClose: () => window.alert('Called when I close')
                    });
                });
        }






    }

    return (
        <>
            <ToastContainer />
            <ReactTooltip />
            <LoginCheck />

            <div className="section-title">Add Money</div>
            <div className="row">
                <div className="col-md-12">
                    <div className="dash-bg-card card-bg-addmoney mb-4">
                        <div className="row">
                            <div className="col-md-3">
                                <BankDepositWizard
                                    active1={true}
                                    completed1={completed}
                                    completed2={completed2}
                                    completed3={completed3}
                                    completed4={completed4}
                                    active2={active2}
                                    active3={active3}
                                    active4={active4}
                                    label4="Upload"
                                />
                            </div>
                            <div className="col-md-9 position-relative">
                                {
                                    step == 2 &&
                                    <>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="deposit-wrap">
                                                    <h4 className="mt-4 mb-4">Bank Wire</h4>
                                                    <ul className="payment-modes">
                                                        {/* <li>
                                                            <a className={TargetCryptoCurrency == "USD" ? "active" : ""} onClick={(e) => setTargetCryptoCurrency("USD", e)} href="#">
                                                                <div className="county-info">
                                                                    <img src={USD} />
                                                                    <label>USD</label>
                                                                </div>
                                                                <div className="price">
                                                                    <h6>USD {amount}</h6>
                                                                    <span>$1 = {rates.USD} USD</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className={TargetCryptoCurrency == "AED" ? "active" : ""} onClick={(e) => setTargetCryptoCurrency("AED", e)} href="#">
                                                                <div className="county-info">
                                                                    <img src={AED} />
                                                                    <label>AED</label>
                                                                </div>
                                                                <div className="price">
                                                                    <h6>USD {amount}</h6>
                                                                    <span>$1 = {rates.AED} AED</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className={TargetCryptoCurrency == "EUR" ? "active" : ""} href="#" onClick={(e) => setTargetCryptoCurrency("EUR", e)}>
                                                                <div className="county-info">
                                                                    <img src={EUR} />
                                                                    <label>EUR</label>
                                                                </div>
                                                                <div className="price">
                                                                    <h6>USD {amount}</h6>
                                                                    <span>$1 = {rates.EUR} EUR</span>
                                                                </div>
                                                            </a>
                                                        </li> */}
                                                        <li>
                                                            <a className={TargetCryptoCurrency == "PKR" ? "active" : ""} href="#" onClick={(e) => setTargetCryptoCurrency("PKR", e)}>
                                                                <div className="county-info">
                                                                    <img src={PAK} />
                                                                    <label>PKR {amount}</label>
                                                                    {/* <label>PKR {(amount * rates.PKR).toFixed(2)}</label> */}
                                                                </div>
                                                                <div className="price">
                                                                    {/* <h6>USD {amount}</h6>
                                                                    <span>$1 = {rates.PKR} PKR</span> */}
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>


                                                    <div className="row g-3 my-4">
                                                        <div className="col-md-6">
                                                            <NavLink to="/partner/addmoney" className="button button-outline-bs button-icon-bs "><ArrowLeftCircleIcon /><span>Modify Amount</span></NavLink>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <button onClick={NextStep3}
                                                                className="button button-bs button-icon-bs"
                                                            ><span>Continue Next</span><ArrowLongRightIcon /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="copy-ticket">

                                                    <ul>
                                                        <li>
                                                            <span>Bank Name</span>
                                                            <h6>Habib Metro</h6>
                                                        </li>
                                                        <li>
                                                            <span>Account Title </span>
                                                            <h6>MAG ORGANIC MEATS</h6>
                                                        </li>
                                                        <li>
                                                            <span>Account Number</span>
                                                            <h6>6027320311714132207</h6>
                                                        </li>
                                                        <li>
                                                            <span>IBAN</span>
                                                            <h6>PK98MPBL0273027140132207</h6>
                                                        </li>
                                                    </ul>
                                                    <div className="ticket-qr">
                                                        <QRCode
                                                            size={256}
                                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                            value="PK98MPBL0273027140132207"
                                                            viewBox={`0 0 256 256`}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ticket-instruction">
                                                    <h5>Instructions</h5>
                                                    <ol>
                                                        <li>Deposit funds in Bank.</li>
                                                        <li>Take a clear and legible photo or scan of the receipt.</li>
                                                        <li>Make sure all relevant information is visible, including the date, time, and amount of the of the deposit, as well as any bank or account information.</li>
                                                        <li>Click on the upload button and select the file containing the receipt from your device</li>
                                                        <li>Wait for the upload to complete, and verify that the file has been successfully uploaded</li>
                                                        <li>Submit the uploaded receipt and wait for confirmation that it has been received and processed.</li>
                                                    </ol>
                                                    <h5>Note</h5>
                                                    <ul>
                                                        <li>
                                                            For international payments, use our crypto payment methods instead of Bank Transfer.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    step == 3 &&
                                    <>
                                        <div className="row">
                                            <div className="col-md-7">
                                                <div className="deposit-wrap">
                                                    <h4 className="mt-4">Bank Wire</h4>
                                                    <p className="m-0">Upload Clear & Readable document. Upload Accurate Document</p>
                                                    <span style={{ color: '#525866', fontSize: '16px' }}>
                                                        ( Kindly attach your deposit slip, invoice, transfer detail, screenshot etc)
                                                    </span>

                                                    <div className="upload-img gray my-3 w-100">
                                                        <div className="card-inner">
                                                            <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                                                                id="drop-area"
                                                                onDragOver={handleDragOver}
                                                                onDrop={handleDrop}
                                                            >
                                                                <input id="ChoosePassportimage" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                                                                {
                                                                    imageSelected ?
                                                                        <>
                                                                            <Cropper
                                                                                // style={{ width: '550px', height: '220px;', marginTop: '-10px' }}
                                                                                zoomTo={0.5}
                                                                                preview=".img-preview"
                                                                                src={image}
                                                                                dragMode="move"
                                                                                viewMode={3}
                                                                                minCropBoxHeight={10}
                                                                                minCropBoxWidth={10}
                                                                                background={false}
                                                                                responsive={true}
                                                                                autoCropArea={1}
                                                                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                                onInitialized={(instance) => {
                                                                                    setCropper(instance);
                                                                                }}
                                                                                guides={true}
                                                                            />
                                                                            <div className="delete" onClick={DeleteImage}>
                                                                                <TrashIcon />

                                                                            </div>
                                                                        </>

                                                                        :
                                                                        <>
                                                                            <label htmlFor="ChoosePassportimage" className='thumnail'>
                                                                                <ViewfinderCircleIcon />
                                                                                <h6>Drag and drop your document</h6>
                                                                                <p>Max size 5 MB. Files allowed: JPG, PNG</p>
                                                                            </label>

                                                                        </>

                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="icon-input">
                                                        <input
                                                            placeholder='Transaction ID / Bank reference'
                                                            className=""
                                                            value={TransactionID}
                                                            onChange={(e) => setTransactionID(e.target.value)}
                                                            required
                                                        />
                                                    </div>

                                                    <p className="note">
                                                        Kindly input accurate Bank Reference
                                                    </p>

                                                    <div className="icon-input">
                                                        <textarea
                                                            placeholder='Comments (Optional)'
                                                            value={Comments}
                                                            onChange={(e) => setComments(e.target.value)}
                                                        />
                                                    </div>
                                                    <p className="note">
                                                        Note: Payment status will be updated within 48 hours
                                                    </p>
                                                    <div className="row g-3">
                                                        <div className="col-md-6">
                                                            <button onClick={NextStep4}
                                                                disabled={!imageSelected || loadingSaveSlip || TransactionID.length == 0}
                                                                className="button button-bs button-icon-bs"
                                                            ><span>Confirm Upload {loadingSaveSlip ? '...' : ''}</span><ArrowLongRightIcon /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </>
                                }
                                {
                                    step == 4 &&
                                    <>
                                        <div className="text-center mt-5">
                                            <h4>Completed</h4>
                                            <p>
                                                Thank you for providing payment information. Payment verification process
                                                may take time according to the payment method used. After successful confirmation
                                                received, the payment will be immediately transferred to your account.

                                            </p>
                                            <Link to={`/partner/dashboard`} className="button button-bs button-icon-bs">
                                                <ArrowLongLeftIcon />
                                                <span>Back to Dashboard</span>
                                            </Link>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>



        </>
    );
}

export default MoneyBankDeposit;