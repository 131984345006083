import React, { useState, useEffect } from "react";
import axios from "axios";
import stamp_paper_img from "../../assets/images/partner/stamp_paper.png";
import stamp from "../../assets/images/partner/mag_stemp_png.png";
import moment from "moment";
import Config from "../../Config";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { NumberFormat } from "../Dashboard/General/Functions";

function formatPrice(value) {
  if (value >= 10000000) {
    return (value / 10000000) + ' crore';
  } else if (value >= 100000) {
    return (value / 100000) + ' lac';
  } else if (value >= 1000) {
    return (value / 1000) + ' thousand';
  } else {
    // return value.toString();
  }
}


const All_In_OneAgreement = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const Full_Name = useState(localStorage.getItem("Full_Name"));
  const [loading, setLoading] = useState(false);
  const [AgreementData, setAgreementData] = useState([]);
  const [Kyc, setKyc] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);

    const PackageID = urlParams.get("PackageID");
    const SecretID = urlParams.get("SecretID");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: PackageID,
      SecretID: SecretID,
    };

    var api_config = {
      method: "post",
      url: Config.base_url + "Package/GetPackagebySecretID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // console.log(data);

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setAgreementData(response.data.PackagePurchase);
          setKyc(response.data.KYC);
          setLoading(false);
        }
        else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/login");
          localStorage.clear();
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center text-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <div className="page agreement-print">
          <img src={stamp_paper_img} alt="" />
          <div className="subpage">
            <h3 className="text-center"><b>Purchase Agreement</b></h3>
            <br />
            {/* <h5>Agreement Date: {moment(AgreementData?.BuyingDate).format(Config.date_format)}.</h5> */}
            <p>
              This Purchase Agreement is made at Islamabad on this <strong>{moment(AgreementData?.BuyingDate).format(Config.date_format)}</strong> by and Between <strong> MAG Ventures ( SMC-PVT ) LTD, Corporate
                Unique Identification No. ( 0250862 )</strong> having Address, <strong>"Rauf Tower First Floor, Main Gt Road, Jinnah Blvd west, DHA Phase 2, Islamabad."</strong> (hereinafter called the <strong>"Owner/ Sellerr/ Company"</strong> ) ,of the
              one part.
              <br /> <div className="text-center"> <strong>AND</strong> </div>
              <strong>{Kyc.FirstName} {Kyc.LastName}</strong> {Kyc.RelationName} <strong>{Kyc.FatherName} </strong>
              {Kyc.DocumentType == "License" ? (
                <>
                  License No. <strong>{Kyc.LicenseNumber}</strong>
                </>
              ) : Kyc.DocumentType === "ID" ? (
                <>
                  CNIC No. <strong>{Kyc.IDCardNumber}</strong>
                </>
              ) : Kyc.DocumentType === "Passport" ? (
                <>
                  Passport No. <strong>{Kyc.PassportNumber}</strong>
                </>
              ) : (
                <></>
              )} Resident of <strong>{Kyc.Address} </strong>
              (hereinafter called the <strong>"Purchaser/ Stakeholder"</strong> ) of the other part.
            </p>
            <p>
              The objectives of this Agreement are to encourage expansion and
              diversification of trade between the Parties.
            </p>
            <ol>
              <li>
                The Stakeholder agrees to
                <strong> Purchase and Trade</strong> a {AgreementData?.selectedPackage?.Name} Package having
                <strong> {AgreementData?.selectedPackage?.TagLine?.replace("Trade :", "").trim()}</strong> for
                <strong> PKR {formatPrice(AgreementData.PackagePrice)} {''} ({NumberFormat(AgreementData.PackagePrice)}) </strong> and the duration of agreement or commitment is<strong> {AgreementData?.selectedPackage?.NoofDays} Days.</strong>
              </li>
              <li>
                This purchase agreement is dated{" "}
                <strong> {moment(AgreementData?.BuyingDate).format(Config.date_format)}{" "}</strong>
                and the product delivery date is{" "}
                <strong> {moment(AgreementData?.ClosingDate).format(Config.date_format)}.</strong>
              </li>
              <li>
                The Stakeholder agrees to pay the specified purchase amount for the
                chosen products that is <strong> {AgreementData?.selectedPackage?.TagLine?.replace("Trade :", "").trim()}.</strong>
              </li>

              <li>
                The Company will generate revenue through  <strong>partnerships with trusted contractors and supply premium
                  mutton and beef to vendors, exporters and slaughterhouses</strong>, and also through the <strong>export of meat nationally
                    and internationally.</strong> Company will pay the profit to the stakeholders on the <strong>1st date</strong> of every month. As per the
                time commitment specified for the chosen product. Profit Distribution shall commence from the first month
                following the payment date.
              </li>
              <li>
                The Company will be responsible to <strong> deliver the product/Capital Refund</strong> ,i.e. <strong>{AgreementData?.selectedPackage?.TagLine?.replace("Trade :", "").trim()} </strong> on
                time and in case of any loss the company will recover it.
              </li>
              <li>
                For <strong>early termination of agreement</strong>, the Stakeholder must provide MAG VENTURES (SMC-PVT) LTD
                with <strong>sixty (60) days' prior written notice</strong>. Failure to do so, resulting in unilateral termination without notice,
                absolves the Company of any obligation to pay the Stakeholder any amount, including the initial capital or profits.
                Upon early termination by the stakeholder, the <strong>Company shall deduct a penalty of forty percent (40%)</strong> from
                the initial Capital as well as will deduct any return sent to stakeholder prior to this. After which if any amount is
                still pending will be paid to the Stakeholder within sixty (60) working days via official company cheque
              </li>
              <li>
                The <strong>Company reserves the right to take legal action</strong> against the Stakeholder to recover any damages,
                financial losses, or costs incurred as a result of the Stakeholder’s actions, including but not limited to, breach of
                this Agreement, unilateral termination, or failure to fulfill contractual obligations.

              </li>
              <li>
                Both Parties agree not to breach this contract under any circumstances. This agreement is <strong>governed by the
                  laws of Pakistan.</strong> Any disputes arising out of or in connection with this agreement shall be settled through
                arbitration in accordance with the rules of the Pakistan Arbitration Association. This agreement constitutes the
                entire understanding between the Parties and supersedes all prior agreements, negotiations, and discussions.
                Amendments to this agreement shall be valid only if made in writing and signed by both Parties. This agreement
                shall be binding upon and inure to the benefit of the Parties and their respective successors and assigns.

              </li>
            </ol>
            <p>
              This agreement constitutes the entire agreement between the
              parties with respect to the subject matter hereof, and supersedes
              all pre-existing agreements and understandings between them with
              respect thereto.
            </p>
            <p>
              This Agreement creates a joint venture relationship between the
              Company and the User.
            </p>
            <p>
              In witness whereof, the Parties have executed this agreement as of
              the date first above written
            </p>

            <div className="agreement-footer">
              <div className="left">
                <p className="lable">Name of Stakeholder</p>
                <div className="line">
                  <p>{Full_Name}</p>
                </div>
              </div>
              <div className="right">
                <p className="lable">Name of Company Representative</p>
                <div className="line">
                  <p>Syed Mohsin Sultan (CEO)</p>
                </div>
              </div>
            </div>
            <div className="agreement-footer">
              <div className="left">
                <p className="lable">
                  Signature of Stakeholder
                </p>
                <div className="line">
                  <p className="opacity-0">wwwwwwwwwwwwwww</p>
                </div>
              </div>
              <div className="right">
                <p className="lable">Signature of Company</p>
                <div className="line">
                  <p className="opacity-0">wwwwwwwwwwwwwww</p>
                </div>
              </div>
            </div>
            <div className="agreement-footer mt-5">
              <div className="left">

              </div>
              <div className="right">
                <img src={stamp} alt="stamp" className="stamp" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default All_In_OneAgreement