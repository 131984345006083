import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import Config from "../../../Config";
import { NumberFormat } from "../../Dashboard/General/Functions";
import TransactionMenu from "./TransactionMenu";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import BsPagination from "../BsPagination";
import LoginCheck from "../General/LoginCheck";

function CryptoDeposits(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const Currency = localStorage.getItem("Currency");
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPaid, settotalPaid] = useState(0);



    useEffect(() => {

        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            PageNo: pageNo,
            PageSize: pageSize,

        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetDeposits',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };
        // console.log(dashboardParms);

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setLedger(response.data.ListofOrders);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                settotalPaid(response.data.totalPaid);
            })
            .catch(function (error) {
                console.log(error);
            });



    }, [pageNo, pageSize]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };




    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {


                    return (
                        <tr key={index}>
                            <td>
                                {item.PaymentID}
                            </td>
                            <td> {moment(item.Dated).format('DD/MM/YYYY HH:mm')}</td>

                            <td>
                                {
                                    item.payment_status == "waiting" ?
                                        <span>{item.Amount}</span>
                                        :
                                        <span>{item.Amount} ({item.PaymentMode})</span>
                                }
                            </td>
                            <td>
                                {Currency} {NumberFormat(item.ReceivedUSD)}
                            </td>
                            <td>
                                {item.Status}
                            </td>
                            <td>{item.Message}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="6">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }

    }

    return (
        <>
            <LoginCheck />
            <TransactionMenu />
            <div className="partner-table">
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Transaction ID</th>
                                <th>Date</th>
                                <th>Deposit Amount</th>
                                <th>Received in {Currency}</th>
                                <th>Status</th>
                                <th>Notes</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    renderLedger()
                            }
                            {
                                listOfLedger != null && listOfLedger.length > 0 &&
                                <tr>
                                    <td colSpan={3} className="text-end"><b>Total Deposit:</b></td>
                                    <td colSpan={3}><b>{Currency} {NumberFormat(totalPaid)} </b></td>
                                </tr>
                            }

                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&

                        <BsPagination
                            length={listOfLedger.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default CryptoDeposits;