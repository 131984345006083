import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "remixicon/fonts/remixicon.css";
import "./assets/css/style.css";
import "./assets/css/circle.css";

// slick slider

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AdminDashboard from "./components/Admin/AdminDashboard";
import AdminLogin from "./components/Admin/AdminLogin";
import AdminLayout from "./components/Admin/AdminLayout";
import DashboardOne from "./components/Admin/DashboardOne";
import UserStatisticsDashboard from "./components/Admin/UserStatistics/UserStatisticsDashboard";
import FundDashboard from "./components/Admin/Fund/FundDashboard";
import MembersList from "./components/Admin/UserStatistics/MembersList";
import BalanceStatisticsDashboard from "./components/Admin/BalanceStatistics/BalanceStatisticsDashboard";
import TotalTransferDashboard from "./components/Admin/TotalTransfers/TotalTransferDashboard";
import AdminFeeDashboard from "./components/Admin/AdminFee/AdminFeeDashboard";
import TotalPackagesDashboard from "./components/Admin/TotalPackages/TotalPackagesDashboard";
import MonthlyFundsDashboard from "./components/Admin/MonthlyFund/MonthlyFundsDashboard";
import CompanyProfitDashboard from "./components/Admin/CompanyProfit/CompanyProfitDashboard";
import DepositDashboard from "./components/Admin/Deposit/DepositDashboard";
import WithdrawalDashboard from "./components/Admin/Withdrawal/WithdrawalDashboard";
import BonusPanelDashboard from "./components/Admin/BonusPanel/BonusPanelDashboard";
import PassiveBonusDashboard from "./components/Admin/PassiveBonus/PassiveBonusDashboard";
import RewardBonusDashboard from "./components/Admin/RewardBonus/RewardBonusDashboard";
import OtherRewardBonusDashboard from "./components/Admin/OtherRewardBonus/OtherRewardBonusDashboard";
import CommodityPannelDashboard from "./components/Admin/CommodityPanel/CommodityPannelDashboard";
import RoiDashboard from "./components/Admin/Roi/RoiDashboard";
import Investus2020Dashboard from "./components/Admin/InvestusClub/Investus2020Dashboard";
import TreeBonusDashboard from "./components/Admin/TreeBonus/TreeBonusDashboard";
import MartixBonusDashboard from "./components/Admin/MatrixBonus/MartixBonusDashboard";
import AdminCommodityDashboard from "./components/Admin/Commodity/AdminCommodityDashboard";
import KycRequest from "./components/Admin/UserStatistics/KycRequests";
import UserDetailsPage from "./components/Admin/UserStatistics/UserDetailsPage";
import TotalPackageList from "./components/Admin/Fund/TotalPackageList";
import CompanyProfitList from "./components/Admin/CompanyProfit/CompanyProfitList";
import CryptoDeposit from "./components/Admin/Deposit/CryptoDeposit";
import NowPayment from "./components/Admin/Deposit/NowPayment";
import CoinPayment from "./components/Admin/Deposit/CoinPayment";
import TotalDeposit from "./components/Admin/Deposit/TotalDeposit";
import CryptoWithdrawalList from "./components/Admin/transactions/Withdrawal/CryptoWithdrawalList";
import DirectBonusList from "./components/Admin/BonusPanel/DirectBonusList";
import NetworkBonusList from "./components/Admin/BonusPanel/NetworkBonusList";
import ReferralBonusList from "./components/Admin/BonusPanel/ReferralBonusList";
import RenewalBonusList from "./components/Admin/BonusPanel/RenewalBonusList";
import RoiBonusList from "./components/Admin/PassiveBonus/RoiBonusList";
import TreeBonusList from "./components/Admin/PassiveBonus/TreeBonusList";
import MatrixBonusList from "./components/Admin/PassiveBonus/MatrixBonusList";

import Investus20_20List from "./components/Admin/PassiveBonus/Investus20_20List";
import RankRewardList from "./components/Admin/RewardBonus/RankRewardList";
import TopPerformerBonusList from "./components/Admin/RewardBonus/TopPerformerBonusList";
import AmassadorBonusList from "./components/Admin/RewardBonus/AmassadorBonusList";
import OtherRewardsList from "./components/Admin/OtherRewardBonus/OtherRewardsList";
import AdminInvestorBonus from "./components/Admin/CommodityPanel/AdminInvestorBonus";
import AdminRepurchaseBonus from "./components/Admin/CommodityPanel/AdminRepurchaseBonus";
import AdminRpInvestorBonus from "./components/Admin/CommodityPanel/AdminRpInvestorBonus";
import SupportDashboard from "./components/Admin/Support/SupportDashboard";
import OpenTicketList from "./components/Admin/Support/OpenTicketList";
import Approved from "./components/Admin/Withdrawal/Approved";
import Rejected from "./components/Admin/Withdrawal/Rejected";
import Succeeded from "./components/Admin/Withdrawal/Succeeded";
import Canceled from "./components/Admin/Withdrawal/Canceled";
import Initiated from "./components/Admin/Withdrawal/Initiated";
import AdminDirectBonus from "./components/Admin/CommodityPanel/AdminDirectBonus";
import SecurityDashboard from "./components/Admin/Security/SecurityDashboard.js";
import ManageUsers from "./components/Admin/Security/ManageUsers";
import AddUser from "./components/Admin/Security/AddUser";
import UpdateUser from "./components/Admin/Security/UpdateUser";
import ManageGroup from "./components/Admin/Security/ManageGroup";
import AddGroup from "./components/Admin/Security/AddGroup";
import UpdateGroup from "./components/Admin/Security/UpdateGroup";
import RightAssignment from "./components/Admin/Security/RightAssignment";
import NoAccess from "./components/Admin/NoAccess";
import AdminTicketsDetail from "./components/Admin/Support/AdminTicketsDetail";
import CloseTicketList from "./components/Admin/Support/CloseTicketList";
import PendingTicketList from "./components/Admin/Support/PendingTicketList";
import RejectedDeposit from "./components/Admin/Deposit/RejectedDeposit";
import PendingDeposit from "./components/Admin/Deposit/PendingDeposit";
import NewTicketList from "./components/Admin/Support/NewTicketList";
import TotalFundsTransfer from "./components/Admin/BalanceStatistics/TotalFundsTransfer";
import Fund_Panel_List from "./components/Admin/Fund/FUND_PANEL_List";
import Transferlist from "./components/Admin/TotalTransfers/Transferlist";
import AdminFeeList from "./components/Admin/AdminFee/AdminFeeList";
import PackagesStatisticsList from "./components/Admin/PackageStatistics/PackagesStatisticsList.js";
import MonthlyFundStatistics from "./components/Admin/MonthlyFund/MonthlyFundStatistics";
import TradingProfilt from "./components/Admin/CommodityPanel/TradingProfilt";
import BankDeposit from "./components/Admin/Deposit/BankDeposit";
import Overview from "./components/Admin/UserStatistics/Overview";
import Funds_Transfer from "./components/Admin/UserStatistics/Funds_Transfer";
import Stateements from "./components/Admin/UserStatistics/Stateements";
import Update_Profile from "./components/Admin/UserStatistics/Update_Profile";
import Active_Wallet from "./components/Admin/transactions/Active_Wallet";
import Passive_Wallet from "./components/Admin/transactions/Passive_Wallet";
import Reward_Wallet from "./components/Admin/transactions/Reward_Wallet";
import OtherReward from "./components/Admin/RewardBonus/OtherReward";
import Schedulers from "./components/Admin/Reports/Schedulers";
import ApprovedWithdrawalListpdf from "./components/Admin/Withdrawal/ApprovedWithdrawalListpdf.js";
import SucceededWithdrawalListpdf from "./components/Admin/Withdrawal/SucceededWithdrawalListpdf.js";
import Downline_Activation from "./components/Admin/TotalTransfers/Downline_Activation.js";
import RoiTransaction from "./components/Admin/Reports/RoiTransaction.js";
import CryptoDepositListpdf from "./components/Admin/Deposit/CryptoDepositpdf.js";
import AmbassadorPoolSummary from "./components/Admin/Reports/AmbassadorPoolSummary.js";
import CashbackMembershipsSummary from "./components/Admin/Reports/CashbackMembershipsSummary.js";
import ClubReturnSummary from "./components/Admin/Reports/ClubReturnSummary.js";
import CommodityCashbackSummary from "./components/Admin/Reports/CommodityCashbackSummary.js";
import CommodityRoiSummary from "./components/Admin/Reports/CommodityRoiSummary.js";
import GlobalShareSummary from "./components/Admin/Reports/GlobalShareSummary.js";
import InvestusPoolSummary from "./components/Admin/Reports/InvestusPoolSummary.js";
import MatrixBonusSummary from "./components/Admin/Reports/MatrixBonusSummary.js";
import MatrixTreeSummary from "./components/Admin/Reports/MatrixTreeSummary.js";
import NetworkBonusSummary from "./components/Admin/Reports/NetworkBonusSummary.js";
import PassiveWalletSummary from "./components/Admin/Reports/PassiveWalletSummary.js";
import PromoVoucherSummary from "./components/Admin/Reports/PromoVoucherSummary.js";
import RenewBinaryMembershipsSummary from "./components/Admin/Reports/RenewBinaryMembershipsSummary.js";
import RepurchaseMembershipsSummary from "./components/Admin/Reports/RepurchaseMembershipsSummary.js";
import SettleSummary from "./components/Admin/Reports/SettleSummary.js";
import TopPerformerPoolSummary from "./components/Admin/Reports/TopPerformerPoolSummary.js";
import TreeBonusSummary from "./components/Admin/Reports/TreeBonusSummary.js";
import PromoDashboard from "./components/Admin/Promo/PromoDashboard.js";
import PromoList from "./components/Admin/Promo/PromoList.js";
import AdminProfileUpdate from "./components/Admin/AdminProfileUpdate.js";
import AdminFoundationList from "./components/Admin/AdminFee/AdminFoundationList.js";
import TotalDepositListpdf from "./components/Admin/Deposit/TotalDepositListpdf.js";
import GeneratedBonus from "./components/Admin/CommodityPanel/GeneratedBonus.js";
import CommodityMembership from "./components/Admin/CommodityPanel/CommodityMembership.js";
import InitiatedWithdrawalListpdf from "./components/Admin/Withdrawal/InitiatedWithdrawalListpdf.js";
import TotalMemberEarning from "./components/Admin/TotalMemberEarning.js";
import RejectedWithdrawalListpdf from "./components/Admin/Withdrawal/RejectedWithdrawalListpdf.js";
import CancelWithdrawalListpdf from "./components/Admin/Withdrawal/CancelWithdrawalListpdf.js";
import CryptoDepositpdf from "./components/Admin/Deposit/CryptoDepositpdf.js";
import Coinpaymentpdf from "./components/Admin/Deposit/Coinpaymentpdf.js";
import NowPaymentpdf from "./components/Admin/Deposit/NowPaymentpdf.js";
import BusinessPackages from "./components/business/Packages/BusinessPackages.js";
import PackagesDetails from "./components/business/Packages/PackagesDetails.js";
import BusinessReward from "./components/business/BusinessReward.js";
import BusinessMain from "./components/business/BusinessMain.js";
import BusinessDashboard from "./components/business/BusinessDashboard.js";
import Login from "./components/account/Login.js";
import PartnerSignUp from "./components/account/PartnerSignUp.js";
import AddPackage from "./components/Admin/Packages/AddPackage.js";
import ManagePackage from "./components/Admin/Packages/ManagePackage.js";
import UpdatePackage from "./components/Admin/Packages/UpdatePackage.js";
import Transactions from "./components/business/Transactions/Transactions.js";
import CryptoDeposits from "./components/business/Transactions/CryptoDeposits.js";
import InternalTransaction from "./components/business/Transactions/InternalTransaction.js";
import KycNew from "./components/business/BusinessKyc/KycNew.js";
import KycSubmitted from "./components/business/BusinessKyc/KycSubmitted.js";
import ActiveWallet from "./components/business/Transactions/ActiveWallet.js";
import ReferralIncome from "./components/business/Transactions/ReferralIncome.js";
import RenewalIncome from "./components/business/Transactions/RenewalIncome.js";
import ROIIncome from "./components/business/Transactions/ROIIncome.js";
import TreeIncome from "./components/business/Transactions/TreeIncome.js";
import RanksRewardTransactions from "./components/business/Transactions/RanksRewardTransactions.js";
import VoucherRedeemed from "./components/business/Transactions/VoucherRedeemed.js";
import WithdrawalRequests from "./components/business/Transactions/WithdrawalRequests.js";
import BalanceTransfer from "./components/business/Transactions/BalanceTransfer.js";
import TransferHistory from "./components/business/Transactions/TransferHistory.js";
import TransferHistoryReceived from "./components/business/Transactions/TransferHistoryReceived.js";
import SentHistory from "./components/business/Transactions/SentHistory.js";
import ReceivedHistory from "./components/business/Transactions/ReceivedHistory.js";
import AddMoney from "./components/business/AddMoney/AddMoney.js";
import AddMoneyCrypto from "./components/business/AddMoney/AddMoneyCrypto.js";
import BusinessFoundation from "./components/business/BusinessFoundation.js";
import MoneyBankDeposit from "./components/business/AddMoney/MoneyBankDeposit.js";
import Kyc from "./components/business/BusinessKyc/Kyc.js";
import BusinessWallet from "./components/business/Wallets/BusinessWallet.js";
import KycApproved from "./components/business/BusinessKyc/KycApproved.js";
import Network from "./components/business/Network/Network.js";
import EStatement from "./components/business/Statement/EStatement.js";
import EstatementPrint from "./components/business/Statement/EstatementPrint.js";
import Account from "./components/business/Profile/Account.js";
import ProfileKyc from "./components/business/Profile/ProfileKyc.js";
import AccountSecurity from "./components/business/Profile/AccountSecurity.js";
import PoolReward from "./components/business/Transactions/PoolReward.js";
import BusinessWithdraw from "./components/business/Withdraw/BusinessWithdraw.js";
import Forgot from "./components/account/Forgot.js";
import ResetPassword from "./components/account/ResetPassword.js";
import VerifyEmail from "./components/account/VerifyEmail.js";
import EmailNotVerified from "./components/account/EmailNotVerified.js";
import ManageWallets from "./components/business/Wallets/ManageWallets.js";
import CoinSuccess from "./components/business/AddMoney/CoinSuccess.js";
import CoinCancel from "./components/business/AddMoney/CoinCancel.js";
import Recharge from "./components/business/Transactions/Recharge.js";
import EmailVerify from "./components/account/EmailVerify.js";
import Invoicepdf from "./components/business/Transactions/Invoicepdf.js";
import WithdrawInvoicepdf from "./components/business/Transactions/WithdrawInvoicepdf.js";
import Agreement_iphone15 from "./components/Admin/Agreement_iphone15.js";
import Agreement_15ProMax from "./components/Admin/Agreement_15ProMax.js";
import Agreement_15Pro_ProMax from "./components/Admin/Agreement_15Pro_ProMax.js";
import Agreement_AppleWatch from "./components/Admin/Agreement_AppleWatch.js";
import TransferInvoice from "./components/business/Transactions/TransferInvoice.js";
import WithdrawInvoice from "./components/business/Transactions/WithDrawInvoice.js";
import OtherTransactions from "./components/business/Transactions/OtherTransactions.js";
import FranchiseLayout from "./components/franchise/FranchiseLayout.js";
import FranchiseLogin from "./components/franchise/FranchiseLogin.js";
import AddMember from "./components/franchise/AddMember.js";
import FranchiseDashboard from "./components/franchise/FranchiseDashboard.js";
import UsedBalance from "./components/franchise/UsedBalance.js";
import FeeReceive from "./components/franchise/FeeReceive.js";
import FranchiseWithdraw from "./components/franchise/FranchiseWithdraw.js";
import FranchiseWithdrawalDashboard from "./components/franchise/Withdrawal/FranchiseWithdrawalDashboard.js";
import FranchiseApproved from "./components/franchise/Withdrawal/FranchiseApproved.js";
import FranchiseInitiated from "./components/franchise/Withdrawal/FranchiseInitiated.js";
import FranchiseRejected from "./components/franchise/Withdrawal/FranchiseRejected.js";
import FranchiseCanceled from "./components/franchise/Withdrawal/FranchiseCanceled.js";
import FranchiseSucceeded from "./components/franchise/Withdrawal/FranchiseSucceeded.js";
import ViewFeeReceived from "./components/franchise/ViewFeeReceived.js";
import PrintAddress from "./components/franchise/PrintAddress.js";
import Agreement_AppleWatchPdf from "./components/franchise/AgreementPdf/Agreement_AppleWatchPdf.js";
import CashInList from "./components/franchise/CashInList.js";
import PrintCashInList from "./components/franchise/PrintCashInList.js";
import CashOutForm from "./components/franchise/CashOutForm.js";
import CashOutList from "./components/franchise/CashOutList.js";
import FranchiseKycRequests from "./components/franchise/kyc/FranchiseKycRequests.js";
import FranchiseUserDetails from "./components/franchise/kyc/FranchiseUserDetails.js";
import FranchiseKyc from "./components/franchise/kyc/FranchiseKyc.js";
import FranchiseApprovedWithdrawalListpdf from "./components/franchise/Withdrawal/FranchiseApprovedWithdrawalListpdf.js";
import FranchiseSucceededWithdrawalListpdf from "./components/franchise/Withdrawal/FranchiseSucceededWithdrawalListpdf.js";
import FranchiseInitiatedWithdrawalListpdf from "./components/franchise/Withdrawal/FranchiseInitiatedWithdrawalListpdf.js";
import FranchiseRejectedWithdrawalListpdf from "./components/franchise/Withdrawal/FranchiseRejectedWithdrawalListpdf.js";
import FranchisePurchasePackage from "./components/franchise/FranchisePurchasePackage.js";
import RecentNotification from "./components/Admin/RecentNotification.js";
import ReceivedBalance from "./components/franchise/ReceivedBalance.js";
import BankDepositPdf from "./components/Admin/Deposit/BankDepositPdf.js";
import PendingDepositPdf from "./components/Admin/Deposit/PendingDepositPdf.js";
import RejectedDepositPdf from "./components/Admin/Deposit/RejectedDepositPdf.js";
import FranchisePurchasePackageList from "./components/franchise/FranchisePurchasePackageList.js";
import PackagePurchasePrint from "./components/franchise/PackagePurchasePrint.js";
import FranchiseSetting from "./components/franchise/Setting/FranchiseSetting.js";
import FranchiseCancelWithdrawalListpdf from "./components/franchise/Withdrawal/FranchiseCancelWithdrawalListpdf.js";
import FranchiseesDashboard from "./components/Admin/Franchisees/FranchiseesDashboard.js";
import StateementsPdf from "./components/Admin/UserStatistics/StateementsPdf.js";
import Leadership from "./components/Admin/Leadership/Leadership.js";
import PackagesStatisticsListPdf from "./components/Admin/PackageStatistics/PackagesStatisticsListPdf.js";
import LeadershipView from "./components/Admin/Leadership/LeadershipView.js";
import RoiTransactionPdf from "./components/Admin/Reports/Pdf/RoiTransactionPdf.js";
import LeadershipPdf from "./components/Admin/Leadership/LeadershipPdf.js";
import LeadershipViewPdf from "./components/Admin/Leadership/LeadershipViewPdf.js";
import TransferlistPdf from "./components/Admin/TotalTransfers/TransferlistPdf.js";
import Downline_ActivationPdf from "./components/Admin/TotalTransfers/Downline_ActivationPdf.js";
import TotalFundsTransferPdf from "./components/Admin/BalanceStatistics/TotalFundsTransferPdf.js";
import OverviewPdf from "./components/Admin/UserStatistics/OverviewPdf.js";
import MembersListPdf from "./components/Admin/UserStatistics/MembersListPdf.js";
import DirectBonusListPdf from "./components/Admin/BonusPanel/DirectBonusListPdf.js";
import ReferralBonusListPdf from "./components/Admin/BonusPanel/ReferralBonusListPdf.js";
import RoiBonusListPdf from "./components/Admin/PassiveBonus/RoiBonusListPdf.js";
import TreeBonusListPdf from "./components/Admin/PassiveBonus/TreeBonusListPdf.js";
import RankRewardPdf from "./components/Admin/RewardBonus/RankRewardPdf.js";
import OtherRewardPdf from "./components/Admin/RewardBonus/OtherRewardPdf.js";
import StarterPackage from "./components/Admin/StarterPackage.js";
import StandardPackage from "./components/Admin/StandardPackage.js";
import SuperiorPackage from "./components/Admin/SuperiorPackage.js";
import LeaderPackage from "./components/Admin/LeaderPackage.js";
import FreedomPackage from "./components/Admin/FreedomPackage.js";
import BasicPackage from "./components/Admin/BasicPackage.js";
import ViewTeam from "./components/Admin/Leadership/ViewTeam.js";
import FranchiseDetail from "./components/Admin/Franchisees/FranchiseDetail.js";
import All_In_OneAgreement from "./components/Admin/All_In_OneAgreement.js";
import ViewTeamPdf from "./components/Admin/Leadership/ViewTeamPdf.js";
import MyTeamLeadershipHistory from "./components/Admin/Leadership/MyTeamLeadershipHistory.js";
import Events from "./components/Admin/Events/Events.js";
import EditEvents from "./components/Admin/Events/EditEvents.js";
import AddEvents from "./components/Admin/Events/AddEvents.js";
import ImageCrop from "./components/Admin/Packages/ImageCrop.js";
import UserPromoList from "./components/Admin/UserDashPromo/UserPromoList.js";
import AddPromo from "./components/Admin/UserDashPromo/AddPromo.js";
import UpdatePromo from "./components/Admin/UserDashPromo/UpdatePromo.js";



const path = window.location.pathname;

const URLs = ["/franchise/cash-in-list-print/", "/franchise/purchase-package-list/"];

if (URLs.some(url => path.startsWith(url))) {
  const meta = document.createElement("meta");
  meta.name = "viewport";
  meta.content = "width=device-width, initial-scale=0.5";
  document.head.appendChild(meta);
}



function Index() {

  if (window.location.host == "localhost:3000" || window.location.host == "localhost:3001"
    || window.location.host == "offlinepartner.mag.holdings"
    || window.location.host == "partner.mag.holdings") {
    return (
      <BrowserRouter>
        <Routes>

          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<PartnerSignUp />} />
          <Route path="/signup/:id" element={<PartnerSignUp />} />
          <Route path="/signup/verify-email" element={<EmailVerify />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/franchise/login" element={<FranchiseLogin />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/email-not-verified" element={<EmailNotVerified />} />


          <Route
            path="/admin/withdrawal-dashboard/Approved-List-pdf"
            element={<ApprovedWithdrawalListpdf />}
          />

          <Route
            path="/admin/deposit-dashboard/crypto-deposit-List-pdf"
            element={<CryptoDepositListpdf />}
          />
          <Route
            path="/admin/withdrawal-dashboard/succeed-List-pdf"
            element={<SucceededWithdrawalListpdf />}
          />
          <Route
            path="/admin/withdrawal-dashboard/initiated-List-pdf"
            element={<InitiatedWithdrawalListpdf />}
          />
          <Route
            path="/admin/withdrawal-dashboard/canceled-List-pdf"
            element={<CancelWithdrawalListpdf />}
          />
          <Route
            path="/admin/withdrawal-dashboard/rejected-List-pdf"
            element={<RejectedWithdrawalListpdf />}
          />
          <Route
            path="/admin/deposit-dashboard/bank-deposit-pdf"
            element={<BankDepositPdf />}
          />

          <Route
            path="/admin/deposit-dashboard/pending-deposit-pdf"
            element={<PendingDepositPdf />}
          />
          <Route
            path="/admin/deposit-dashboard/rejected-deposit-pdf"
            element={<RejectedDepositPdf />}
          />
          <Route
            path="/admin/withdrawal-dashboard/Total-deposit-List-pdf"
            element={<TotalDepositListpdf />}
          />

          <Route
            path="/admin/deposit-dashboard/crypto-deposit-pdf"
            element={<CryptoDepositpdf />}
          />
          <Route
            path="/admin/deposit-dashboard/Coinpayment-pdf"
            element={<Coinpaymentpdf />}
          />
          <Route
            path="/admin/deposit-dashboard/NowPayment-pdf"
            element={<NowPaymentpdf />}
          />
          <Route
            path="/partner/transactions/print-statement"
            element={<EstatementPrint />}
          />
          <Route
            path="/partner/transactions/funds-history/sent/pdf"
            element={<Invoicepdf />}
          />
          <Route
            path="/partner/transactions/funds-history/sent/img"
            element={<TransferInvoice />}
          />
          <Route
            path="/partner/transactions/withdraw/img"
            element={<WithdrawInvoice />}
          />
          <Route
            path="/partner/transactions/withdraw/pdf"
            element={<WithdrawInvoicepdf />}
          />

          <Route
            path="/package/agreement/:id"
            element={<All_In_OneAgreement />}
          />
          {/* <Route
            path="/package/agreement/1"
            element={<Agreement_AppleWatch />}
          />
          <Route
            path="/package/agreement/2"
            element={<Agreement_iphone15 />}
          />
          <Route
            path="/package/agreement/3"
            element={<Agreement_15ProMax />}
          />
          <Route
            path="/package/agreement/4"
            element={<Agreement_15Pro_ProMax />}
          />
          <Route
            path="/package/agreement/5"
            element={<StarterPackage />}
          />
          <Route
            path="/package/agreement/6"
            element={<StandardPackage />}
          />
          <Route
            path="/package/agreement/7"
            element={<SuperiorPackage />}
          />
          <Route
            path="/package/agreement/8"
            element={<FreedomPackage />}
          />
          <Route
            path="/package/agreement/9"
            element={<LeaderPackage />}
          />
          <Route
            path="/package/agreement/10"
            element={<BasicPackage />}
          />
          <Route
            path="/package/pdf-agreement/1"
            element={<Agreement_AppleWatchPdf />}
          /> */}
          <Route
            path="/package/agreement/2/pdf"
            element={<Agreement_iphone15 />}
          />
          <Route
            path="/package/agreement/3/pdf"
            element={<Agreement_15ProMax />}
          />
          <Route
            path="/package/agreement/4/pdf"
            element={<Agreement_15Pro_ProMax />}
          />
          <Route
            path="/admin/userstatistics-dashboard/stateements-pdf"
            element={<StateementsPdf />}
          />

          <Route
            path="/admin/total-packages-dashboard/Packages-Statistics-list/pdf"
            element={<PackagesStatisticsListPdf />}
          />
          <Route
            path="/admin/reports/Scheduler/roi-Summary-pdf"
            element={<RoiTransactionPdf />}
          />
          <Route
            path="/admin/userstatistics-dashboard/leadership/pdf"
            element={<LeadershipPdf />}
          />

          <Route
            path="/admin/userstatistics-dashboard/leadership-view-team/pdf"
            element={<ViewTeamPdf />}
          />

          <Route
            path="/admin/userstatistics-dashboard/leadership-view/pdf"
            element={<LeadershipViewPdf />}
          />
          <Route
            path="/admin/total-transfer-dashboard/Transferlist/pdf"
            element={<TransferlistPdf />}
          />
          <Route
            path="/admin/total-transfer-dashboard/Downline-Activation/pdf"
            element={<Downline_ActivationPdf />}
          />

          <Route
            path="/admin/balance-statistics-dashboard/total-funds-transfer/pdf"
            element={<TotalFundsTransferPdf />}
          />

          <Route
            path="/admin/Members-overview/pdf"
            element={<OverviewPdf />}
          />
          <Route
            path="/admin/userstatistics-dashboard/Total-user/pdf"
            element={<MembersListPdf />}
          />

          <Route
            path="/admin/active-bonus-dashboard/direct-bonus-list/pdf"
            element={<DirectBonusListPdf />}
          />
          <Route
            path="/admin/active-bonus-dashboard/referral-bonus-list/pdf"
            element={<ReferralBonusListPdf />}
          />
          <Route
            path="/admin/passive-bonus-dashboard/roi-bonus/pdf"
            element={<RoiBonusListPdf />}
          />

          <Route
            path="/admin/passive-bonus-dashboard/tree-bonus/pdf"
            element={<TreeBonusListPdf />}
          />
          <Route
            path="/admin/reward-bonus-dashboard/rank/pdf"
            element={<RankRewardPdf />}
          />
          <Route
            path="/admin/reward-bonus-dashboard/other_reward/pdf"
            element={<OtherRewardPdf />}
          />
          <Route path="/partner" element={<BusinessMain />}>
            <Route exact path="/partner/dashboard" element={<BusinessDashboard />} />
            <Route path="/partner/packages" element={<BusinessPackages />} />
            <Route path="/partner/packages/view/:id" element={<PackagesDetails />} />
            <Route path="/partner/wallet" element={<BusinessWallet />} />
            <Route path="/partner/rewards" element={<BusinessReward />} />
            <Route path="/partner/foundation" element={<BusinessFoundation />} />
            <Route path="/partner/withdraw" element={<BusinessWithdraw />} />


            <Route
              path="/partner/profile/wallets"
              element={<ManageWallets />}
            />


            {/* KYC */}
            <Route
              path="/partner/kyc-new"
              element={<KycNew />}
            />
            <Route
              path="/partner/kyc/submitted"
              element={<KycSubmitted />}
            />
            <Route
              path="/partner/kyc/congratulations"
              element={<KycApproved />}
            />


            <Route
              path="/partner/kyc"
              element={<Kyc />}
            />

            {/* add money */}

            <Route path="/partner/addmoney" element={<AddMoney />} />
            <Route
              path="/partner/addmoney/:amount"
              element={<AddMoneyCrypto />}
            />

            <Route
              path="/partner/bank-deposit/:amount"
              element={<MoneyBankDeposit />}
            />

            <Route path="/partner/bank-deposit" element={<BankDeposit />} />

            {/* Transactions */}

            <Route
              path="/partner/transactions/membership"
              element={<Transactions />}
            />



            {/* smart-wallet */}

            <Route
              path="/partner/transactions/smart-wallet/direct-bonus"
              element={<ActiveWallet />}
            />

            <Route
              path="/partner/transactions/smart-wallet/referral-bonus"
              element={<ReferralIncome />}
            />
            <Route
              path="/partner/transactions/smart-wallet/cashback-bonus"
              element={<RenewalIncome />}
            />

            {/* Profit Wallet */}

            <Route
              path="/partner/transactions/profit-wallet/roi-income"
              element={<ROIIncome />}
            />

            <Route
              path="/partner/transactions/profit-wallet/tree-income"
              element={<TreeIncome />}
            />

            <Route
              path="/partner/transactions/profit-wallet/internal-transaction"
              element={<InternalTransaction />}
            />

            {/* Treasure Wallet */}

            <Route
              path="/partner/transactions/treasure-wallet/ranks-reward"
              element={<RanksRewardTransactions />}
            />
            <Route
              path="/partner/transactions/treasure-wallet/pool-reward"
              element={<PoolReward />}
            />
            <Route
              path="/partner/transactions/treasure-wallet/promotion"
              element={<VoucherRedeemed />}
            />

            {/* deposits */}
            <Route
              path="/partner/transactions/deposits"
              element={<CryptoDeposits />}
            />


            <Route
              path="/partner/transactions/Others"
              element={<OtherTransactions />}
            />

            <Route path="/partner/coinpayments/success" element={<CoinSuccess />} />
            <Route path="/partner/coinpayments/cancel" element={<CoinCancel />} />


            {/* withdraw */}
            <Route
              path="/partner/transactions/withdraw"
              element={<WithdrawalRequests />}
            />

            <Route
              path="/partner/balance-transfer"
              element={<BalanceTransfer />}
            />

            <Route
              path="/partner/transactions/funds-history/sent"
              element={<TransferHistory />}
            />



            <Route
              path="/partner/transactions/funds-history/received"
              element={<TransferHistoryReceived />}
            />
            {/* Activation History */}
            <Route
              path="/partner/transactions/activation-history/history/sent"
              element={<SentHistory />}
            />
            <Route
              path="/partner/transactions/activation-history/history/Recharge"
              element={<Recharge />}
            />
            <Route
              path="/partner/transactions/activation-history/history/received"
              element={<ReceivedHistory />}
            />
            {/* network */}
            <Route path="/partner/network" element={<Network />} />
            {/* statement */}

            <Route
              path="/partner/statement"
              element={<EStatement />}
            />


            {/* profile */}

            <Route path="/partner/profile/account" element={<Account />} />
            <Route path="/partner/profile-kyc" element={<ProfileKyc />} />
            <Route path="/partner/profile/security" element={<AccountSecurity />} />
          </Route>

          <Route path="/admin" element={<AdminLayout />}>
            <Route path="/admin/image-croper/:id" element={<ImageCrop />} />
            <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/dashboard1" element={<DashboardOne />} />
            <Route path="/admin/Admin-Update-Profile" element={<AdminProfileUpdate />} />
            <Route path="/admin/package/add-package" element={<AddPackage />} />
            <Route path="/admin/package/manage-package" element={<ManagePackage />} />
            <Route path="/admin/package/update-package/:id" element={<UpdatePackage />} />



            <Route path="/admin/events" element={<Events />} />
            <Route path="/admin/edit-events/:id" element={<EditEvents />} />
            <Route path="/admin/add-events" element={<AddEvents />} />

            <Route
              path="/admin/balance-statistics-dashboard"
              element={<BalanceStatisticsDashboard />}
            />
            <Route
              path="/admin/recent-notification"
              element={<RecentNotification />}
            />
            <Route
              path="/admin/balance-statistics-dashboard/total-funds-transfer"
              element={<TotalFundsTransfer />}
            />
            <Route
              path="/admin/dashboard/total-member-earning"
              element={<TotalMemberEarning />}
            />

            <Route
              path="/admin/balance-statistics/TOTAL FUNDS TRANSFER"
              element={<BalanceStatisticsDashboard />}
            />

            <Route
              path="/admin/total-transfer-dashboard"
              element={<TotalTransferDashboard />}
            />
            <Route
              path="/admin/total-transfer-dashboard/Transferlist"
              element={<Transferlist />}
            />
            <Route
              path="/admin/total-transfer-dashboard/Downline-Activation"
              element={<Downline_Activation />}
            />
            <Route
              path="/admin/admin-fee-dashboard"
              element={<AdminFeeDashboard />}
            />
            <Route
              path="/admin/admin-fee-dashboard/admin-fee-list"
              element={<AdminFeeList />}
            />
            <Route
              path="/admin/admin-fee-dashboard/admin-Foundation-list"
              element={<AdminFoundationList />}
            />
            <Route
              path="/admin/total-packages-dashboard"
              element={<TotalPackagesDashboard />}
            />

            <Route
              path="/admin/monthly-funds/dashboard"
              element={<MonthlyFundsDashboard />}
            />
            <Route
              path="/admin/monthly-funds/dashboard/monthly-fund-statistic"
              element={<MonthlyFundStatistics />}
            />

            {/* <Route
            path="/admin/packages-statistics/dashboard"
            element={<PackagesStatisticsDashboard />}
          /> */}
            <Route
              path="/admin/total-packages-dashboard/Packages-Statistics-list"
              element={<PackagesStatisticsList />}
            />

            <Route
              path="/admin/support-dashboard/detail/:id"
              element={<AdminTicketsDetail />}
            />

            {/* user statisics  */}

            <Route
              path="/admin/userstatistics-dashboard"
              element={<UserStatisticsDashboard />}
            />
            <Route
              path="/admin/userstatistics-dashboard/userstatus/:userstatus"
              element={<MembersList />}
            />
            <Route
              path="/admin/userstatistics-dashboard/Total-user"
              element={<MembersList />}
            />
            <Route
              path="/admin/userstatistics-dashboard/KycRequests"
              element={<KycRequest />}
            />
            <Route
              path="/admin/userstatistics-dashboard/leadership"
              element={<Leadership />}
            />
            <Route
              path="/admin/userstatistics-dashboard/leadership-view-team/:id"
              element={<ViewTeam />}
            />
            <Route
              path="/admin/userstatistics-dashboard/leadership-view/:id"
              element={<LeadershipView />}
            />
            <Route
              path="/admin/userstatistics-dashboard/leadership-view/my-team-history/:id"
              element={<MyTeamLeadershipHistory />}
            />
            <Route
              path="/admin/Members-overview"
              element={<Overview />}
            />
            <Route
              path="/admin/userstatistics-dashboard/Stateements"
              element={<Stateements />}
            />
            <Route
              path="/admin/userstatistics-dashboard/Update_Profile"
              element={<Update_Profile />}
            />
            <Route
              path="/admin/userstatistics-dashboard/Funds_Transfer"
              element={<Funds_Transfer />}
            />
            <Route
              path="/admin/userstatistics-dashboard/user-details/:KYCID"
              element={<UserDetailsPage />}
            />

            {/* start dashboard 1 side bar  */}
            {/* company profit  */}

            <Route
              path="/admin/company-profit/dashboard"
              element={<CompanyProfitDashboard />}
            />
            <Route
              path="/admin/company-profit/dashboard/total-profit"
              element={<CompanyProfitList />}
            />

            {/* deposit panel  */}

            <Route
              path="/admin/deposit-dashboard"
              element={<DepositDashboard />}
            />
            <Route
              path="/admin/deposit-dashboard/total-deposit"
              element={<TotalDeposit />}
            />
            <Route
              path="/admin/deposit-dashboard/crypto-deposit"
              element={<CryptoDeposit />}
            />
            <Route
              path="/admin/deposit-dashboard/coin-payment"
              element={<CoinPayment />}
            />
            <Route
              path="/admin/deposit-dashboard/now-payment"
              element={<NowPayment />}
            />
            <Route
              path="/admin/deposit-dashboard/rejected-deposit"
              element={<RejectedDeposit />}
            />
            <Route
              path="/admin/deposit-dashboard/pending-deposit"
              element={<PendingDeposit />}
            />
            <Route
              path="/admin/deposit-dashboard/bank-deposit"
              element={<BankDeposit />}
            />

            {/* withdrawal panel  */}

            <Route
              path="/admin/withdrawal-dashboard"
              element={<WithdrawalDashboard />}
            />
            <Route
              path="/admin/withdrawal-dashboard/initiated"
              element={<Initiated />}
            />
            <Route
              path="/admin/withdrawal-dashboard/approved"
              element={<Approved />}
            />

            <Route
              path="/admin/withdrawal-dashboard/rejected"
              element={<Rejected />}
            />
            <Route
              path="/admin/withdrawal-dashboard/success"
              element={<Succeeded />}
            />
            <Route
              path="/admin/withdrawal-dashboard/canceled"
              element={<Canceled />}
            />
            <Route
              path="/admin/deposit/coin-payment"
              element={<CoinPayment />}
            />
            <Route path="/admin/deposit/now-payment" element={<NowPayment />} />

            {/* bonus panel  */}

            <Route
              path="/admin/active-bonus-dashboard"
              element={<BonusPanelDashboard />}
            />
            <Route
              path="/admin/active-bonus-dashboard/direct-bonus-list"
              element={<DirectBonusList />}
            />
            <Route
              path="/admin/active-bonus-dashboard/network-bonus-list"
              element={<NetworkBonusList />}
            />
            <Route
              path="/admin/active-bonus-dashboard/referral-bonus-list"
              element={<ReferralBonusList />}
            />
            <Route
              path="/admin/active-bonus-dashboard/renewal-bonus-list"
              element={<RenewalBonusList />}
            />

            {/* passive bonus panel  */}

            <Route
              path="/admin/passive-bonus-dashboard"
              element={<PassiveBonusDashboard />}
            />
            <Route
              path="/admin/passive-bonus-dashboard/roi-bonus"
              element={<RoiBonusList />}
            />
            <Route
              path="/admin/passive-bonus-dashboard/tree-bonus"
              element={<TreeBonusList />}
            />
            <Route
              path="/admin/passive-bonus-dashboard/matrix-bonus"
              element={<MatrixBonusList />}
            />
            <Route
              path="/admin/passive-bonus-dashboard/investus20_20-bonus"
              element={<Investus20_20List />}
            />

            {/* reward bonus panel  */}

            <Route
              path="/admin/reward-bonus-dashboard"
              element={<RewardBonusDashboard />}
            />
            <Route
              path="/admin/reward-bonus-dashboard/rank"
              element={<RankRewardList />}
            />
            <Route
              path="/admin/reward-bonus-dashboard/top-performer"
              element={<TopPerformerBonusList />}
            />
            <Route
              path="/admin/reward-bonus-dashboard/top-ambassador"
              element={<AmassadorBonusList />}
            />
            <Route
              path="/admin/reward-bonus-dashboard/other_reward"
              element={<OtherReward />}
            />

            {/* other reward bonus panel  */}

            <Route
              path="/admin/other-reward-bonus/dashboard"
              element={<OtherRewardBonusDashboard />}
            />
            <Route
              path="/admin/other-reward-bonus/list"
              element={<OtherRewardsList />}
            />

            {/* franchisees panel  */}

            <Route
              path="/admin/franchisees-dashboard"
              element={<FranchiseesDashboard />}
            />
            <Route
              path="/admin/franchisees-dashboard/detail"
              element={<FranchiseDetail />}
            />
            {/* commodity panel  */}

            <Route
              path="/admin/commodity-panel-dashboard"
              element={<CommodityPannelDashboard />}
            />
            <Route
              path="/admin/commodity-panel-dashboard/generated-bonus"
              element={<GeneratedBonus />}
            />
            <Route
              path="/admin/commodity-panel-dashboard/direct-bonus"
              element={<AdminDirectBonus />}
            />
            <Route
              path="/admin/commodity-panel-dashboard/investor-bonus"
              element={<AdminInvestorBonus />}
            />
            <Route
              path="/admin/commodity-panel-dashboard/repurchase-bonus"
              element={<AdminRepurchaseBonus />}
            />
            <Route
              path="/admin/commodity-panel-dashboard/rp-investor-bonus"
              element={<AdminRpInvestorBonus />}
            />
            <Route
              path="/admin/commodity-panel-dashboard/Commodity-Membership"
              element={<CommodityMembership />}
            />
            <Route
              path="/admin/commodity-panel-dashboard/trading-profit"
              element={<TradingProfilt />}
            />

            {/* start dashboard 3 side bar  */}
            <Route path="/admin/roi/dashboard" element={<RoiDashboard />} />
            <Route
              path="/admin/investus2020/dashboard"
              element={<Investus2020Dashboard />}
            />
            <Route
              path="/admin/treebonus/dashboard"
              element={<TreeBonusDashboard />}
            />
            <Route
              path="/admin/matrix-bonus/dashboard"
              element={<MartixBonusDashboard />}
            />
            <Route
              path="/admin/commodity/dashboard"
              element={<AdminCommodityDashboard />}
            />
            <Route path="/admin/fund/dashboard" element={<FundDashboard />} />
            <Route
              path="/admin/fund/total-packages"
              element={<TotalPackageList />}
            />
            <Route
              path="/admin/dashboard/fund/fund-panel-list"
              element={<Fund_Panel_List />}
            />

            {/* ADMIN TRANSACTIONS DASHBOARD */}

            <Route
              path="/admin/active-bonus-dashboard/Active_Wallet"
              element={<Active_Wallet />}
            />

            <Route
              path="/admin/passive-bonus-dashboard/Passive_Wallet"
              element={<Passive_Wallet />}
            />
            <Route
              path="/admin/reward-bonus-dashboard/Reward_Wallet"
              element={<Reward_Wallet />}
            />
            <Route
              path="/admin/transaction/crypto-withdrawal-list"
              element={<CryptoWithdrawalList />}
            />

            {/* ADMIN SUPPORTPANEL */}

            <Route
              path="/admin/support-dashboard"
              element={<SupportDashboard />}
            />
            <Route
              path="/admin/support-dashboard/open-ticket"
              element={<OpenTicketList />}
            />
            <Route
              path="/admin/support-dashboard/new-ticket"
              element={<NewTicketList />}
            />
            <Route
              path="/admin/support-dashboard/close-ticket"
              element={<CloseTicketList />}
            />
            <Route
              path="/admin/support-dashboard/pending-ticket"
              element={<PendingTicketList />}
            />

            {/* Schedulers */}
            <Route
              path="/admin/reports/Scheduler"
              element={<Schedulers />}
            />
            <Route
              path="/admin/reports/Scheduler/roi-Summary"
              element={<RoiTransaction />}
            />
            <Route
              path="/admin/reports/Scheduler/AmbassadorPool-Summary"
              element={<AmbassadorPoolSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/CashbackMemberships-Summary"
              element={<CashbackMembershipsSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/ClubReturn-Summary"
              element={<ClubReturnSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/CommodityCashback-Summary"
              element={<CommodityCashbackSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/CommodityRoi-Summary"
              element={<CommodityRoiSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/GlobalShare-Summary"
              element={<GlobalShareSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/InvestusPool-Summary"
              element={<InvestusPoolSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/MatrixBonus-Summary"
              element={<MatrixBonusSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/MatrixTree-Summary"
              element={<MatrixTreeSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/NetworkBonus-Summary"
              element={<NetworkBonusSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/PassiveWallet-Summary"
              element={<PassiveWalletSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/PromoVoucher-Summary"
              element={<PromoVoucherSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/RenewBinaryMemberships-Summary"
              element={<RenewBinaryMembershipsSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/RepurchaseMemberships-Summary"
              element={<RepurchaseMembershipsSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/Settle-Summary"
              element={<SettleSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/TopPerformerPool-Summary"
              element={<TopPerformerPoolSummary />}
            />
            <Route
              path="/admin/reports/Scheduler/TreeBonus-Summary"
              element={<TreeBonusSummary />}
            />


            {/* Promo */}
            <Route
              path="/admin/Promo-dashboard"
              element={<PromoDashboard />}
            />
            <Route
              path="/admin/Promo-dashboard/PromoList"
              element={<PromoList />}
            />

            <Route
              path="/admin/promo/manage-promo"
              element={<UserPromoList />}
            />
            <Route
              path="/admin/promo/add-promo"
              element={<AddPromo />}
            />
            <Route
              path="/admin/promo/update-promo/:id"
              element={<UpdatePromo />}
            />

            {/* ADMIN SECURITY PANEL */}

            <Route
              path="/admin/security-dashboard"
              element={<SecurityDashboard />}
            />
            <Route
              path="/admin/security-dashboard/manage-users"
              element={<ManageUsers />}
            />
            <Route
              path="/admin/security-dashboard/manage-users/add"
              element={<AddUser />}
            />
            <Route
              path="/admin/security-dashboard/manage-users/edit/:id"
              element={<UpdateUser />}
            />
            <Route
              path="/admin/security-dashboard/manage-groups"
              element={<ManageGroup />}
            />
            <Route
              path="/admin/security-dashboard/manage-groups/add"
              element={<AddGroup />}
            />
            <Route
              path="/admin/security-dashboard/manage-groups/edit/:id"
              element={<UpdateGroup />}
            />
            <Route
              path="/admin/security-dashboard/manage-groups/right-assignment/:id"
              element={<RightAssignment />}
            />


            <Route path="/admin/no-access" element={<NoAccess />} />
          </Route>

          <Route
            path="/franchise/print-address/:id"
            element={<PrintAddress />}
          />

          <Route
            path="/franchise/cash-in-list-print/:id"
            element={<PrintCashInList />}
          />


          <Route
            path="/franchise/withdrawal-dashboard/Approved-List-pdf"
            element={<FranchiseApprovedWithdrawalListpdf />}
          />

          <Route
            path="/franchise/withdrawal-dashboard/succeed-List-pdf"
            element={<FranchiseSucceededWithdrawalListpdf />}
          />
          <Route
            path="/franchise/withdrawal-dashboard/initiated-List-pdf"
            element={<FranchiseInitiatedWithdrawalListpdf />}
          />
          <Route
            path="/franchise/withdrawal-dashboard/canceled-List-pdf"
            element={<FranchiseCancelWithdrawalListpdf />}
          />
          <Route
            path="/franchise/withdrawal-dashboard/rejected-List-pdf"
            element={<FranchiseRejectedWithdrawalListpdf />}
          />

          <Route
            path="/franchise/purchase-package-list/:id"
            element={<PackagePurchasePrint />}
          />

          <Route path="/franchise" element={<FranchiseLayout />}>
            <Route exact path="/franchise/dashboard" element={<FranchiseDashboard />} />
            <Route path="/franchise/setting" element={<FranchiseSetting />} />
            <Route path="/franchise/package/add-package" element={<AddPackage />} />
            <Route path="/franchise/package/manage-package" element={<ManagePackage />} />
            <Route path="/franchise/package/update-package/:id" element={<UpdatePackage />} />


            <Route
              path="/franchise/add-members"
              element={<AddMember />}
            />
            <Route
              path="/franchise/fee-receive"
              element={<FeeReceive />}
            />

            <Route
              path="/franchise/view-fee-receive"
              element={<ViewFeeReceived />}
            />

            <Route
              path="/franchise/cash-in-list"
              element={<CashInList />}
            />
            <Route
              path="/franchise/cash-out-form"
              element={<CashOutForm />}
            />
            <Route
              path="/franchise/cash-out-list"
              element={<CashOutList />}
            />
            <Route
              path="/franchise/add-kyc"
              element={<FranchiseKyc />}
            />
            <Route
              path="/franchise/kyc-request"
              element={<FranchiseKycRequests />}
            />
            <Route
              path="/franchise/kyc-request/:KYCID"
              element={<FranchiseUserDetails />}
            />


            <Route
              path="/franchise/withdrawal-dashboard"
              element={<FranchiseWithdrawalDashboard />}
            />
            <Route
              path="/franchise/withdrawal-dashboard/initiated"
              element={<FranchiseInitiated />}
            />
            <Route
              path="/franchise/withdrawal-dashboard/approved"
              element={<FranchiseApproved />}
            />

            <Route
              path="/franchise/withdrawal-dashboard/rejected"
              element={<FranchiseRejected />}
            />
            <Route
              path="/franchise/withdrawal-dashboard/success"
              element={<FranchiseSucceeded />}
            />
            <Route
              path="/franchise/withdrawal-dashboard/canceled"
              element={<FranchiseCanceled />}
            />

            <Route
              path="/franchise/withdraw"
              element={<FranchiseWithdraw />}
            />
            <Route
              path="/franchise/used-balance"
              element={<UsedBalance />}
            />
            <Route
              path="/franchise/received-balance"
              element={<ReceivedBalance />}
            />
            <Route
              path="/franchise/purchase-package"
              element={<FranchisePurchasePackage />}
            />
            <Route
              path="/franchise/purchase-package-list"
              element={<FranchisePurchasePackageList />}
            />
            <Route path="/franchise/no-access" element={<NoAccess />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
  else if (
    window.location.host == "franchise.mag.holdings" || window.location.host == "offlinefranchise.mag.holdings") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FranchiseLogin />} />
          <Route path="/login" element={<FranchiseLogin />} />
          <Route path="/franchise/login" element={<FranchiseLogin />} />
          <Route
            path="/franchise/print-address/:id"
            element={<PrintAddress />}
          />

          <Route
            path="/franchise/cash-in-list-print/:id"
            element={<PrintCashInList />}
          />
          <Route
            path="/franchise/purchase-package-list/:id"
            element={<PackagePurchasePrint />}
          />

          <Route
            path="/franchise/withdrawal-dashboard/Approved-List-pdf"
            element={<FranchiseApprovedWithdrawalListpdf />}
          />

          <Route
            path="/franchise/withdrawal-dashboard/succeed-List-pdf"
            element={<FranchiseSucceededWithdrawalListpdf />}
          />
          <Route
            path="/franchise/withdrawal-dashboard/initiated-List-pdf"
            element={<FranchiseInitiatedWithdrawalListpdf />}
          />
          <Route
            path="/franchise/withdrawal-dashboard/canceled-List-pdf"
            element={<FranchiseCancelWithdrawalListpdf />}
          />
          <Route
            path="/franchise/withdrawal-dashboard/rejected-List-pdf"
            element={<FranchiseRejectedWithdrawalListpdf />}
          />


          <Route
            path="/package/agreement/:id"
            element={<All_In_OneAgreement />}
          />


          <Route path="/franchise" element={<FranchiseLayout />}>
            <Route exact path="/franchise/dashboard" element={<FranchiseDashboard />} />
            <Route path="/franchise/setting" element={<FranchiseSetting />} />
            <Route path="/franchise/package/add-package" element={<AddPackage />} />
            <Route path="/franchise/package/manage-package" element={<ManagePackage />} />
            <Route path="/franchise/package/update-package/:id" element={<UpdatePackage />} />


            <Route
              path="/franchise/add-members"
              element={<AddMember />}
            />
            <Route
              path="/franchise/fee-receive"
              element={<FeeReceive />}
            />

            <Route
              path="/franchise/view-fee-receive"
              element={<ViewFeeReceived />}
            />

            <Route
              path="/franchise/cash-in-list"
              element={<CashInList />}
            />
            <Route
              path="/franchise/cash-out-form"
              element={<CashOutForm />}
            />
            <Route
              path="/franchise/cash-out-list"
              element={<CashOutList />}
            />
            <Route
              path="/franchise/add-kyc"
              element={<FranchiseKyc />}
            />
            <Route
              path="/franchise/kyc-request"
              element={<FranchiseKycRequests />}
            />
            <Route
              path="/franchise/kyc-request/:KYCID"
              element={<FranchiseUserDetails />}
            />


            <Route
              path="/franchise/withdrawal-dashboard"
              element={<FranchiseWithdrawalDashboard />}
            />
            <Route
              path="/franchise/withdrawal-dashboard/initiated"
              element={<FranchiseInitiated />}
            />
            <Route
              path="/franchise/withdrawal-dashboard/approved"
              element={<FranchiseApproved />}
            />

            <Route
              path="/franchise/withdrawal-dashboard/rejected"
              element={<FranchiseRejected />}
            />
            <Route
              path="/franchise/withdrawal-dashboard/success"
              element={<FranchiseSucceeded />}
            />
            <Route
              path="/franchise/withdrawal-dashboard/canceled"
              element={<FranchiseCanceled />}
            />

            <Route
              path="/franchise/withdraw"
              element={<FranchiseWithdraw />}
            />
            <Route
              path="/franchise/used-balance"
              element={<UsedBalance />}
            />
            <Route
              path="/franchise/received-balance"
              element={<ReceivedBalance />}
            />
            <Route
              path="/franchise/purchase-package"
              element={<FranchisePurchasePackage />}
            />
            <Route
              path="/franchise/purchase-package-list"
              element={<FranchisePurchasePackageList />}
            />

            <Route path="/franchise/no-access" element={<NoAccess />} />
          </Route>
        </Routes>
      </BrowserRouter>
    )
  }
}

//<Route path="*" element={authenticate ? <DashboardHome /> : <Login />} />

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);