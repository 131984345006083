import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";
import Config from "../../../Config";
import { NumberFormat } from "../../Dashboard/General/Functions";
import BsPagination from "../BsPagination";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import TransactionMenu from "./TransactionMenu";
import ActivationMenu from "./ActivationMenu";
import LoginCheck from "../General/LoginCheck";

function SentHistory(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const Currency = localStorage.getItem("Currency");
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);

    const [ledgerOld, setLedgerOld] = useState([]);


    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);





    useEffect(() => {

        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Wallet/GetDownlineActivationPaid',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofOtherReward);
                setLedgerOld(response.data.ListofOtherReward);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                console.log(error);
            });



    }, [pageNo, pageSize]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };


    // filters 






    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {


                    return (
                        <tr key={index}>
                            <td>
                                {item.SecretID}
                            </td>

                            <td>
                                {item.PackageName}
                            </td>
                            <td>
                                {moment(item.Dated).format('DD/MM/YYYY HH:mm')}
                            </td>
                            <td>
                                {item.User_ID}
                            </td>
                            <td>
                                {item.Paid_By}
                            </td>

                            <td>{Currency} {NumberFormat(item.PackageAmount)}</td>
                            <td>{item.Status}</td>

                        </tr>
                    )
                })
            )
        }

        else {
            return (
                <tr>
                    <td className="else" colSpan="7">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }

    }



    return (
        <>
            <LoginCheck />
            <TransactionMenu />
            <div className="partner-table">
                <ActivationMenu />
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Package ID</th>
                                <th>Package</th>
                                <th>Date</th>
                                <th>Purchased for</th>
                                <th>Purchased by</th>
                                <th>Package Value </th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    renderLedger()
                            }

                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&
                       
                        <BsPagination
                            length={listOfLedger.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                          onPageSizeChange={(newPageSize) =>  { 
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default SentHistory;