import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import Config from "../../../Config";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import BsPagination from "../BsPagination";
import TransactionMenu from "./TransactionMenu";
import PassiveMenu from "./PassiveMenu";
import LoginCheck from "../General/LoginCheck";



function ROIIncome(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const Currency = localStorage.getItem("Currency");
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);

    const [ledgerOld, setLedgerOld] = useState([]);
    const [isFilteredByDate, setisFilteredByDate] = useState(false);



    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);



    useEffect(() => {


        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Wallet/GetROIDetails',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofROI);
                setLedgerOld(response.data.ListofROI);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                console.log(error);
            });





    }, [pageNo, pageSize]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const amountTotal = () => {
        let totalAmount = 0;
        listOfLedger.forEach(item => {
            totalAmount += item.Released;

        });

        return <span>{NumberFormat(totalAmount)}</span>

    }






    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {

            let sorted = [...listOfLedger].sort((a, b) => b.ID - a.ID);

            return (
                sorted.map((item, index) => {

                    return (
                        <tr key={index}>

                            <td>{item.PackageSecretID}</td>
                            <td>{moment(item.PackageDate).format('DD/MM/YYYY')}</td>
                            <td>{Currency} {NumberFormat(item.PackageAmount)}</td>
                            <td>{moment(item.RewardDated).format('DD/MM/YYYY')}</td>
                            <td>{item.PercentageReleased}%</td>
                            <td>{Currency} {NumberFormat(item.Released)}</td>
                            <td>{Currency} {NumberFormat(item.TotalReceived)}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="7">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }

    }



    return (
        <>
            <LoginCheck />

            <TransactionMenu />
            <div className="partner-table">
                <PassiveMenu />
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Package ID</th>
                                <th>Purchase Date</th>
                                <th>Package Amount </th>
                                <th>Profit Date</th>
                                <th>Percentage</th>
                                <th>Released </th>
                                <th>Total Received</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    <>
                                        {renderLedger()}
                                        {
                                            listOfLedger != null && listOfLedger.length > 0 &&
                                            <tr>
                                                <td colSpan="5"></td>
                                                <td><strong>Total:</strong></td>
                                                <td><strong>{Currency} {amountTotal()}</strong></td>
                                            </tr>

                                        }
                                    </>
                            }

                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&
                       
                        <BsPagination
                            length={listOfLedger.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                            onPageSizeChange={(newPageSize) =>  { 
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default ROIIncome;